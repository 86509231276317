import styled from "styled-components";
import { breakpoints } from "./size";

export const OverviewWrap = styled.div``;

export const WrappContent = styled.div`
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
  }

  &:nth-child(1) {
    width: 30%;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }
  }
`;

export const LoadingState = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const WrappBottomContent = styled.div`
  margin-top: 40px;
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
  }

  &:nth-child(1) {
    width: 50%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  &:nth-child(2) {
    width: 50%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

export const TopCardsWrap = styled.div`
  display: flex;
  gap: 30px;

  
  > div:nth-child(2) {
    width: 70%;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const BottomCardsWrap = styled.div`
  display: flex;
  gap: 20px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const TopCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const OverviewCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.linesOpacity};
  margin-bottom: 30px;
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: auto;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 0px;
  }
`;

export const InnerContent = styled.div`
  padding: 20px 10px;

  &.statisticsWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;
    gap: 5vw;

    .paramsGroup {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 20%;

      @media only screen and (max-width: 980px) {
      width: 100%;
      }
    }
  }

  .playersStatistic {
    width: 100%%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  .playersStatisticWrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .statistics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    @media only screen and (${breakpoints.mobile}) {
      gap: 10px;
    }

    h5 {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
      max-width: 200px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h4 {
      opacity: 1;
      color: ${(props) => props.theme.white};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }

  .statsPadding{
    padding-right: 5px;
  }
`;

export const Cards = styled.div`
  width: 200px;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    background: transparent;
    color: ${(props) => props.theme.green};
    text-decoration: underline;
    border: none;
    font-family: "SatoshiMedium";
    cursor: pointer;
  }
`;

export const CardsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  gap: 40px;
`;

export const VideoContent = styled.div`
  display: flex;
  flex-directino: row;
  gap: 20px;
  width: 100%;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const VideoStats = styled.div`
  width: 100%;
`;

export const StatsCard = styled.div`
  border-radius: 3px;
  background-color: rgba(54, 232, 45, 0.2);
  padding: 10px;
  margin-bottom: 20px;
  position: relative;

  &.defense {
    background-color: rgba(242, 10, 70, 0.2);

 
  }

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SpaceGrotesk";
    font-size: 28px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 1px;
    text-align: left;
    line-height: 40px;
  }

  h5 {
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const VideoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const VideoItems = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;
export const VideoInfo = styled.div`
  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    cursor: pointer;
  }

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const LoadingVideo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

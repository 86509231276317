import ReactPaginate from 'react-paginate';
import { PageButton } from '../../../style/Favorites';
import arrowDark from "../../../images/arrow_dark.svg";
import arrowLight from "../../../images/arrow_white.svg";
// interface ResultsPaginateProps {
//   items: Array<any>,
//   itemsPerPage: number,
// }

export const Pagination = ({
    page = 0,
    items = 0,
    itemsPerPage = 10,
    onPageChange,
    styleWrapper = {},
    disabled
}) => {
    const pageCount = Math.ceil(items / itemsPerPage);

    const handlePageClick = (event) => {
        console.log(event)
        onPageChange?.(event.selected + 1);
    }
    return (
        <div style={{...styleWrapper, pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1}}>
            <ReactPaginate
                forcePage={page}
                className={'pagination'}
                nextLabel={
                    <PageButton
                        disabled={page + 1 === pageCount}
                    >
                        <img
                            className="rightTransform"
                            src={
                                localStorage.getItem("theme") === "light"
                                    ? arrowLight
                                    : arrowDark
                            }
                            alt={arrowDark}
                        />
                    </PageButton>

                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={4}
                pageCount={pageCount}
                previousLabel={
                    <PageButton
                        disabled={page + 1 === 1}
                    >
                        <img
                            src={
                                localStorage.getItem("theme") === "light"
                                    ? arrowLight
                                    : arrowDark
                            }
                            alt={arrowDark}
                            className="leftTransform"
                        />
                    </PageButton>
                }
                breakLabel='...'
                activeClassName={'paginationActive'}
                renderOnZeroPageCount={null}
                disabledClassName={'paginationDisable'}
            />
        </div>
    )
}

import styled from "styled-components";

export const DropdownWrapper = styled.div`
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.blueish2};
    width: 50%;

    & > div > input {
        background: transparent;
        border: none;
        width: 90%;
        outline: none;
        opacity: 1;
        color: ${(props) => props.theme.blueish};
        font-family: "SatoshiRegular";
        font-size: 16px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: left;
        line-height: 24px;
        padding-left: 5px;
        padding-bottom: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden !important;
    }
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 30px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.blueish3};
  max-height: 200px;
  overflow: scroll;
  border: 1px solid ${(props) => props.theme.borderColor};

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.black};
    }
  }
`;
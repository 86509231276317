import React, { useState } from "react";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  TopTableNames,
  TableNameWrap,
  TableWrap,
  TableContent,
  Player,
  Position,
  Club,
  Passes,
  RightContent,
  SeeMoreWrap,
  Compare,
  ProfileImage,
  ImagesWrap,
  FilterButton,
} from "../../style/SmrtSearch";

// Images
import star from "../../images/ic_start.svg";
import compare from "../../images/ic_compare.svg";
import compareBlue from "../../images/compare_blue.svg";
import filer from "../../images/ic_filter.svg";
import emptyState from "../../images/contactLogo.svg";

import { ReactComponent as SortAscIcon } from "../../images/icons/sortasc_icon.svg";
import { ReactComponent as SortDescIcon } from "../../images/icons/sort_desc.svg";
import { ReactComponent as SortDefaultIcon } from "../../images/icons/sort_default.svg";

// Componenst
import Empty from "../../components/empty/index";
import Filter from "../modal/Filter";
import Modal from "../../components/modal/index";
import Button from "../button";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../notification/useNotification";
import { extractFirstCharacters, getPlayerParamMarkers } from "../../helpers";
import { NotificationList } from "../notification/NotificationList";
import { Tooltip } from "react-tooltip";

const SmrtSearchTable = ({ table, info }) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t, i18n } = useTranslation();
  const [openFilter, setOpenFiler] = useState(false);

  const navigate = useNavigate();

  const { notifications, addNotification, removeNotification } = useNotification();

  const [tableTypeSorting, setTableTypeSorting] = useState({ type: 'default', param: '' }); // type default | asc | desc param:number

  const tableNames = [
    {
      id: 1,
      name: `${t("smrtSearch.player")}`,
    },
    {
      id: 2,
      name: `${t("smrtSearch.position")}`,
    },
    {
      id: 5,
      name: "Goals",
      parameter_id: 2,
    },
    {
      id: 7,
      name: "Assist",
      parameter_id: 201,
    },
    {
      id: 8,
      name: "Mins Played",
      parameter_id: 1,
    },
    {
      id: 4,
      name: "Passes",
      parameter_id: 35,
    },
    {
      id: 3,
      name: "Yellow Card",
      parameter_id: 21,
    },
    {
      id: 6,
      name: "Red Card",
      parameter_id: 23,
    },
  ];

  const isCanClickPlayerStat = (parameter) => {
    return parameter?.count === 0
      || parameter.parameter_name.includes('%')
      || parameter.parameter_name.includes('Mins Played')
  }

  const handleClickPlayerParam = async (param, player) => {
    const { count, parameter_id: param_id, parameter_name } = param;
    const { name, surname, id: player_id } = player;

    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add('loading-cursor');
      const data = await getPlayerParamMarkers(player_id, param_id, '', controller);
      if (data?.results?.length > 0) {
        const eventsWithVideo = data?.results?.filter(item => Boolean(item.video));
        if (eventsWithVideo?.length > 0) {
          const markers = data.results.map(item => item.id).join(',');
          window.open(`/tournaments/match/video?title=${parameter_name} - ${name} ${surname}&markers=${markers}`)
        } else {
          addNotification(`Video not available.`, 'warning')
        }
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive ${count} clips of the player has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving ${count} player clips.`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const [successPopup, setSuccessPopup] = useState(false);

  const handleCompare = (item) => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Convert id to number (assuming id is initially a string)
    const playerId = parseInt(item.id, 10);

    // Check if the item ID is already in the array
    if (!playersArray.includes(playerId)) {
      // Add the new player ID to the array
      playersArray.push(playerId);
      // Store the updated array back in local storage
      localStorage.setItem("players", JSON.stringify(playersArray));
    }

    setSuccessPopup(!successPopup);
  };

  const modal = (
    <>
      {/* Success added to compare  */}
      <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
        <h1>Successfully added to compare</h1>
        <br />
        <Button
          title="Go to compare"
          size={"full"}
          onClick={() => navigate(`/compare`)}
        />
      </Modal>
    </>
  );

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const handleSortTable = (param) => {
    console.log(param);
    if (tableTypeSorting.type === 'asc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'desc', param: param.parameter_id })
    } else if (tableTypeSorting.type === 'desc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'default', param: param.parameter_id })
      console.log('set default sort')
    } else {
      setTableTypeSorting({ type: 'asc', param: param.parameter_id })
    }
  }

  const sortTable = (a, b) => {
    if (tableTypeSorting.param) {
      const paramA = a.params?.find(param => param.parameter_id === tableTypeSorting.param);
      const paramB = b.params?.find(param => param.parameter_id === tableTypeSorting.param);
      if (paramA && paramB) {
        if (tableTypeSorting.type === 'asc') {
          return paramA.count - paramB.count;
        }
        if (tableTypeSorting.type === 'desc') {
          return paramB.count - paramA.count;
        }
      }
      if (paramA) return -1;
      if (paramB) return 1;
    }
    return 0;
  }

  return (
    <>
      {info?.length > 0 ? (
        <>
          {!isMobile && (
            <TopTableNames>
              {tableNames.map((item, index) => {
                return (
                  <TableNameWrap
                    key={item.id}
                    className="tableNameWrapTeamPlayer"
                    onClick={() => handleSortTable(item)}
                    data-tooltip-id={`param-name-${item.id}`}
                  >
                    <h5 >{index < 2 ? item.name : extractFirstCharacters(item.name)}</h5>
                    {
                      index < 2 ? null
                        : tableTypeSorting.type === 'asc' && tableTypeSorting.param === item.parameter_id ? (
                          <SortAscIcon />
                        ) : tableTypeSorting.type === 'desc' && tableTypeSorting.param === item.parameter_id ? (
                          <SortDescIcon />
                        ) : (
                          <SortDefaultIcon />
                        )
                    }
                    <Tooltip
                      id={`param-name-${item.id}`}
                      place="bottom"
                      content={item.name}
                    />
                  </TableNameWrap>
                );
              })}
            </TopTableNames>
          )}
          <TableWrap>
            {info?.sort(sortTable)?.map((item, index) => {
              const photo = table?.find(tableItem => tableItem.id === item.id);
              const params = [
                item?.params?.find(a => a.parameter_id === 2), //goals
                item?.params?.find(a => a.parameter_id === 201), //Assists
                item?.params?.find(a => a.parameter_id === 1), // mins played
                item?.params?.find(a => a.parameter_id === 35), // passes
                item?.params?.find(a => a.parameter_id === 21), // Yellow Cards
                item?.params?.find(a => a.parameter_id === 23), // Red Cards
              ]
              return (
                <TableContent key={index} className="tableLabelWrapTeamPlayer">
                  <Player className="teamPlayerTab" style={{ color: "#fff" }}>
                    <ImagesWrap>
                      <img src={star} alt={star} />
                      <ProfileImage
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/tournaments/player/${item.id}`)
                        }
                        onContextMenu={() =>
                          window.open(
                            `/tournaments/player/${item.id}`,
                            "_blank"
                          )
                        }
                      >
                        <img
                          style={{ width: "40px", height: "40px;" }}
                          src={photo ? photo?.photo : emptyState}
                          alt={photo?.photo}
                        />
                        <span>
                          <img src={photo?.flag} alt={photo?.flag} />
                        </span>
                      </ProfileImage>
                    </ImagesWrap>
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/tournaments/player/${item.id}`)}
                      onContextMenu={() =>
                        window.open(`/tournaments/player/${item.id}`, "_blank")
                      }
                    >
                      {photo?.display_name}
                    </h3>
                  </Player>

                  {!isMobile && (
                    <>
                      <Position className="playerTeamPage">
                        <h3>{photo?.position ? photo?.position : "/"}</h3>
                      </Position>

                      {
                        params?.map((param, index) => {
                          return (
                            <Passes
                              key={index}
                              style={
                                isCanClickPlayerStat(param)
                                  ? { cursor: "not-allowed" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() =>
                                !isCanClickPlayerStat(param) &&
                                handleClickPlayerParam(param, item)
                              }
                            >
                              <h3>
                                {" "}
                                {param.count
                                  ? formatNumber(param.count)
                                  : param.count}
                              </h3>
                            </Passes>
                          );
                        })
                      }
                    </>
                  )}
                  <RightContent>
                    <SeeMoreWrap
                      onClick={() => navigate(`/tournaments/player/${item.id}`)}
                      onContextMenu={() =>
                        window.open(`/tournaments/player/${item.id}`, "_blank")
                      }
                    >
                      {t("smrtSearch.seeMore")}
                    </SeeMoreWrap>

                    {!isMobile && (
                      <Compare onClick={() => handleCompare(item)} data-tooltip-id={`compare-${item.id}`}>
                        {" "}
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? compareBlue
                              : compare
                          }
                          alt={compare}
                        />
                        <Tooltip
                          id={`compare-${item.id}`}
                          place="bottom"
                          content={"ADD TO COMPARE"}
                        />
                      </Compare>
                    )}
                  </RightContent>
                </TableContent>
              );
            })}
          </TableWrap>
        </>
      ) : (
        <Empty
          title={t("smrtSearch.emptySearchTitle")}
          text={t("smrtSearch.emptySearchDesc")}
        />
      )}

      <Filter setOpenFiler={setOpenFiler} openFilter={openFilter} />

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />

      {modal}
    </>
  );
};

export default SmrtSearchTable;

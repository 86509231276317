import React, { useEffect, useMemo, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Style
import {
  CompareSide,
  MainWrapper,
  RightContent,
  TopWrapper,
} from "../../style/DasboardMain";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import CompareSearch from "../../components/inputs/CompareSearch";
import CompareSearchSinglePlayer from "../../components/inputs/CompareSearchSinglePlayer";
import Button from "../../components/button/index";

// Images
import arrowaUp from "../../images/up.svg";
import arrowDown from "../../images/down.svg";
import emptyState from "../../images/contactLogo.svg";
import starOn from "../../images/star_on.svg";
import starOff from "../../images/star_off.svg";
import trash from "../../images/ic_bin.svg";
import trash_blue from "../../images/trash_blue.svg";

// Language
import { useTranslation, Trans } from "react-i18next";
import { $apiV1, $apiV2 } from "../../config/api";
import {
  ComparePlayerContent,
  ComparePlayerInfo,
  ComparePlayerInfoContent,
  ComparePlayerLabels,
  ComparePlayerLabelsContent,
  ComparePlayerList,
  CompareWrapper,
  NameWrapper,
  Parameters,
  ParamsLabel,
  PlayerClubInfo,
  PlayerPhoto,
  PlayerSeason,
  ProgressContent,
  ResetCompareButton,
  SearchPlayer,
  SelectedMatches,
  SelectedMatchesContent,
  SelectedMatchesOptions,
  SelectedMatchesOptionsContent,
} from "../../style/ComparePlayersStyle";
import { useNavigate } from "react-router-dom";
import { displayedParamsFielderIds } from "../smrtSearch";
import { DropdownWrapper, OpenedDropdown } from "../../components/table/MatchTable";
import TextInput from "../../components/inputs/TextInput";
import useOutsideClick from "../../hooks/useOutsideClick";

const ComparePlayerItem = ({
  item,
  getComparePlayers,
  isFavorite,
  toggleFavorite,
  handlePlayerSeasonChange,
  handleRemovePlayer,
  getSpecialSortedParams,
  getParamValue,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState(item?.available_year_seasons?.[0] || null);

  const seasonDropdownRef = useOutsideClick(() => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <ComparePlayerInfo>
      <ComparePlayerInfoContent>
        <CompareSearchSinglePlayer
          id={item.id}
          getComparePlayers={getComparePlayers}
        />

        <PlayerPhoto>
          <img
            className="playerPhoto"
            src={
              item.photos.length > 0
                ? `https://platform.smrtstats.com:8888/${item.photos?.[0]?.image}`
                : emptyState
            }
            alt={emptyState}
          />
          <img
            className="flag"
            src={
              item.nationality.length > 0
                ? `https://platform.smrtstats.com:8888/${item.nationality?.[0]?.flag}`
                : emptyState
            }
          />
        </PlayerPhoto>
        <PlayerClubInfo>
          {item.team ? (
            <h4
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/tournaments/team/${item.team.id}`)
              }
              onContextMenu={() =>
                window.open(
                  `/tournaments/team/${item.team.id}`,
                  "_blank"
                )
              }
            >
              {item.team.name}
            </h4>
          ) : (
            <h4>/</h4>
          )}
          <NameWrapper>
            <h2
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/tournaments/player/${item.id}`)
              }
              onContextMenu={() =>
                window.open(
                  `/tournaments/player/${item.id}`,
                  "_blank"
                )
              }
              data-tooltip-id={`full-name-${item.name}-${item.surname}`}
            >
              {item.name} {item.surname}
            </h2>
            <ReactTooltip
              id={`full-name-${item.name}-${item.surname}`}
              place="bottom"
              content={`${item.name} ${item.surname}`}
            />
            <img
              src={isFavorite ? starOn : starOff}
              alt="Favorite star"
              style={{
                width: "18px",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => toggleFavorite(item)}
            />
            <img
              src={
                localStorage.getItem("theme") === "light"
                  ? trash_blue
                  : trash
              }
              style={{ cursor: "pointer" }}
              onClick={() => handleRemovePlayer(item.id)}
            />
          </NameWrapper>
          {item?.position?.[0] && (
            <h3 className="positionsStyle">
              {item.position[0].name}
            </h3>
          )}
        </PlayerClubInfo>
        <PlayerSeason>
          <DropdownWrapper ref={seasonDropdownRef}>
            <div style={{ position: "relative" }} onClick={() => setOpen(!open)}>
              <TextInput
                type="text"
                value={selectedSeason?.years}
                readOnly
              />

              <img
                src={open ? arrowaUp : arrowDown}
                alt={'arrow'}
                style={{
                  color: "#fff",
                  position: "absolute",
                  right: 0,
                  cursor: "pointer",
                }}
              />
            </div>
            {open && (
              <OpenedDropdown>
                {item?.available_year_seasons?.sort((a, b) => b.id - a.id).map((season) => (
                  <div
                    key={season.id}
                    onClick={() => { handlePlayerSeasonChange(item.id, season.id); setSelectedSeason(season); setOpen(false) }}
                  >
                    {season.years}
                  </div>
                ))}
              </OpenedDropdown>
            )}
          </DropdownWrapper>
          {/* <select
            onChange={(e) =>
              handlePlayerSeasonChange(item.id, e.target.value)
            }
          >
            {item.available_year_seasons?.map((season) => (
              <option key={season.id} value={season.id}>
                Season {season.years}
              </option>
            ))}
          </select> */}
        </PlayerSeason>

        <Parameters>
          {getSpecialSortedParams(item?.params?.[0]?.values?.filter(item => item.parameter_id !== 162))?.map(
            (param, index) => {
              const value = getParamValue(param);
              return (
                <React.Fragment key={param.parameter_id}>
                  <ProgressContent>
                    <progress id="bar" value={value} max="100">
                      {value}%
                    </progress>
                    <h4>{value}</h4>
                  </ProgressContent>
                </React.Fragment>
              );
            }
          )}
        </Parameters>
      </ComparePlayerInfoContent>
    </ComparePlayerInfo>
  )
}

const Index = () => {
  const { t } = useTranslation();
  const [openMatches, setOpenMatches] = useState(false);
  const [listOfPlayers, setListOfPlayers] = useState([]);
  const [favoritePlayers, setFavoritePlayers] = useState({});
  const [selectedMatch, setSelectedMatch] = useState("Total");
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(null);
  const [selectedPlayerSeason, setSelectedPlayerSeason] = useState("");
  const playerSeasons = useRef({});

  const navigate = useNavigate();

  const getComparePlayers = async () => {
    const storedPlayers = localStorage.getItem("players");

    let comparePlayersList = [];
    if (storedPlayers) {
      try {
        comparePlayersList = JSON.parse(storedPlayers);
        if (!Array.isArray(comparePlayersList)) {
          comparePlayersList = [];
        }
      } catch (error) {
        console.error("Error parsing players from local storage:", error);
      }
    }

    try {
      const response = await $apiV2.post(`/platform/comparison_players/`, {
        players: comparePlayersList.map((player) => {
          let obj = {
            player_id: player,
            param_id: displayedParamsFielderIds,
          };
          if (playerSeasons?.current?.[player]) {
            obj.year_season_id = [+playerSeasons.current[player]];
          }
          return obj;
        }),
      });
      setListOfPlayers(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  useEffect(() => {
    getComparePlayers();
  }, [playerSeasons]);

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  useEffect(() => {
    getComparePlayers();

    const handleStorageChange = (event) => {
      if (event.key === "players") {
        getComparePlayers();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [selectedPlayerSeason]);

  const selectedMatchList = [
    {
      id: 1,
      name: "Total",
    },
    {
      id: 2,
      name: "Average Match",
    },
    // Strange results from the server. Uncomment when fixed.
    // {
    //   id: 3,
    //   name: "Average 90",
    // },
  ];

  const handleClickedMatch = (item) => {
    setSelectedMatch(item.name);
    setOpenMatches(!openMatches);
  };

  const handlePlayerSeasonChange = (id, seasonId) => {
    playerSeasons.current = { ...playerSeasons.current, [id]: seasonId };
    // console.log(`Player index changed: ${id}`);
    // console.log(`New season ID for player at index ${id}: ${seasonId}`);

    getComparePlayers(); // Fetch updated player data
  };

  const handleRemoveAllCompare = () => {
    localStorage.removeItem("players");
    setListOfPlayers([]);
  };

  const handleRemovePlayer = (id) => {
    const storedPlayers = localStorage.getItem("players");
    if (storedPlayers) {
      let comparePlayersList = JSON.parse(storedPlayers);
      comparePlayersList = comparePlayersList.filter((item) => item !== id);

      if (comparePlayersList.length === 0) {
        localStorage.removeItem("players");
        setListOfPlayers([]); // Clear the state if no players are left
      } else {
        localStorage.setItem("players", JSON.stringify(comparePlayersList));
        const newCompareListPlayers = listOfPlayers.filter(
          (player) => player.id !== id
        );
        setListOfPlayers(newCompareListPlayers);
      }
    }
  };

  const getParamValue = (param) => {
    try {
      if (param?.value) {
        if (
          param?.parameter_name?.includes("%") ||
          (param?.parameter_id === 1 && selectedMatch === "Total")
        ) {
          return param.value.main;
        } else if (param?.parameter_id === 1) {
          return param?.value?.avg_matches;
        } else {
          const typeParam =
            selectedMatch === "Total"
              ? "main"
              : selectedMatch === "Average Match"
                ? "avg_matches"
                : "avg_90";
          return typeParam === "avg_matches" || typeParam === "avg_90"
            ? param?.value?.[typeParam]
            : param?.value?.[typeParam];
        }
      }
      return 0;
    } catch (e) {
      return param?.value?.main || "-";
    }
  };

  const getSpecialSortedParams = (arr) => {
    try {
      const sortedArr = arr?.sort((a, b) => a.parameter_id - b.parameter_id);
      const assistIndex = sortedArr?.findIndex(
        (item) => item.parameter_id === 201
      );
      const goalIndex = sortedArr?.findIndex((item) => item.parameter_id === 2);
      if (goalIndex + 1 >= arr.length) {
        var k = goalIndex + 1 - arr.length + 1;
        while (k--) {
          sortedArr.push(undefined);
        }
      }
      sortedArr.splice(goalIndex + 1, 0, sortedArr.splice(assistIndex, 1)[0]);
      return sortedArr;
    } catch (e) {
      console.log("error special sort", e);
      return arr?.sort((a, b) => a.parameter_id - b.parameter_id);
    }
  };

  return (
    <MainWrapper>
      <Navigation />
      <CompareSide>
        <TopWrapper style={{ marginBottom: "15px" }}>
          <IntroText
            smallTitle={t("contactDashboard.smallTitle")}
            title="Search & Compare Players."
          />
          <CompareSearch getComparePlayers={getComparePlayers} />
        </TopWrapper>
        <ResetCompareButton>
          {" "}
          <Button title="remove all" onClick={() => handleRemoveAllCompare()} />
        </ResetCompareButton>
        <CompareWrapper>
          <ComparePlayerLabels>
            <ComparePlayerLabelsContent>
              <SelectedMatchesContent>
                <SelectedMatches onClick={() => setOpenMatches(!openMatches)}>
                  <h3>{selectedMatch ?? "Selected Match"}</h3>
                  <img
                    src={openMatches ? arrowaUp : arrowDown}
                    alt={openMatches ? arrowaUp : arrowDown}
                  />
                </SelectedMatches>

                {openMatches && (
                  <SelectedMatchesOptions>
                    <SelectedMatchesOptionsContent>
                      <ul>
                        {selectedMatchList.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => handleClickedMatch(item)}
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </SelectedMatchesOptionsContent>
                  </SelectedMatchesOptions>
                )}
              </SelectedMatchesContent>

              <ParamsLabel>
                <ul>
                  {getSpecialSortedParams(
                    listOfPlayers[0]?.params[0]?.values
                  )?.filter(item => item.parameter_id !== 162)?.map((item, index) => {
                    return <li key={index}>{item.parameter_name}</li>;
                  })}
                </ul>
              </ParamsLabel>
            </ComparePlayerLabelsContent>
          </ComparePlayerLabels>
          <ComparePlayerList>
            <ComparePlayerContent>
              {listOfPlayers?.map((item, index) => {
                const isFavorite = favoritePlayers[item.id] ?? false;

                return (
                  <ComparePlayerItem
                    key={item.id}
                    item={item}
                    getComparePlayers={getComparePlayers}
                    isFavorite={isFavorite}
                    toggleFavorite={toggleFavorite}
                    handlePlayerSeasonChange={handlePlayerSeasonChange}
                    handleRemovePlayer={handleRemovePlayer}
                    getSpecialSortedParams={getSpecialSortedParams}
                    getParamValue={getParamValue}
                  />
                );
              })}
            </ComparePlayerContent>
          </ComparePlayerList>
        </CompareWrapper>
      </CompareSide>
    </MainWrapper>
  );
};

export default Index;

import styled from "styled-components";
import { breakpoints } from "./size";

export const EventsMatchesWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  padding-bottom: 40px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const ListOfMatches = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 35%;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

 
`;

export const EventMatchFilter = styled.div``;

export const ParamsList = styled.div`
  label {
    color: #fff;
  }
`;

export const FootballField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // width: 60%;
  height: 100%;
  z-index: 9;
  margin: 0 auto;
`;

export const FootballFieldCount = styled.div`
  position: relative;
  margin-top: 30px;
`;

export const FieldCount = styled.div`
  position: absolute;
  width: 649px;
  height: 482px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
`;

export const TopGoalFields = styled.div`
  position: absolute;
  width: 480px;
  height: 158px;
  top: 110px;
  left: 51%;
  transform: translateX(-50%);
  background-color: rgba(207, 218, 230, 0.4);
  display: flex;
`;

export const BottomGoalFields = styled.div`
  position: absolute;
  width: 522px;
  height: 158px;
  bottom: 54px;
  left: 50.5%;
  transform: translateX(-50%);
  background-color: rgba(207, 218, 230, 0.4);
  display: flex;
`;

export const TopGoalLeft = styled.div`
  width: 158px;
  height: 158px;
  position: relative;
  overflow: visible; /* Allow the pseudo-element to extend outside */
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -20px;
    width: 0px;
    height: 00px;
    border-left: 20px solid transparent;
    border-bottom: 154px solid rgba(207, 218, 230, 0.4);
    border-top: 0;
    border-right: 0;
    z-index: 1;
  }
`;

export const BottomGoalLeft = styled.div`
  width: 180px;
  height: 158px;
  position: relative;
  overflow: visible; /* Allow the pseudo-element to extend outside */
  border-bottom: 1px solid rgba(0, 0, 0, 1);

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -20px;
    width: 0px;
    height: 00px;
    border-left: 20px solid transparent;
    border-bottom: 154px solid rgba(207, 218, 230, 0.4);
    border-top: 0;
    border-right: 0;
    z-index: 1;
  }
`;

export const TopGoalMiddle = styled.div`
  width: 166px;
  height: 158px;
  border: 1px solid rgba(0, 0, 0, 1);

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomGoalMiddle = styled.div`
  width: 166px;
  height: 158px;
  border: 1px solid rgba(0, 0, 0, 1);
  border-top: 0;

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopGoalRight = styled.div`
  width: 158px;
  height: 158px;
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: -12px;
    width: 0px;
    height: 00px;
    border-right: 12px solid transparent;
    border-bottom: 154px solid rgba(207, 218, 230, 0.4);
    border-top: 0;
    border-left: 0;
    z-index: 1;
  }
`;

export const BottomGoalRight = styled.div`
  width: 160px;
  height: 158px;
  border-bottom: 1px solid rgba(0, 0, 0, 1);

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: -12px;
    width: 0px;
    height: 00px;
    border-right: 12px solid transparent;
    border-bottom: 154px solid rgba(207, 218, 230, 0.4);
    border-top: 0;
    border-left: 0;
    z-index: 1;
  }
`;

export const FieldCountTop = styled.div`
  position: absolute;
  width: 480px;
  height: 482px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const TopFields = styled.div`
  display: flex;
  flex-direction: row;
  height: 241px;
  background-color: rgba(207, 218, 230, 0.4);
`;

export const LeftTopField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;
  border-left: 1px solid rgba(0, 0, 0, 1);
`;
export const MiddleTopField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;

  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`;
export const RightTopField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;
  border-right: 1px solid rgba(0, 0, 0, 1);
`;

export const BottomFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 241px;
  background-color: rgba(207, 218, 230, 0.4);

  border: 1px solid rgba(0, 0, 0, 1);
  opacity: 1;

  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SatoshiBold";
  font-size: 32px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;
`;

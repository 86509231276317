import React from "react";

// Style
import styled, { css } from "styled-components";
import { breakpoints } from "../../style/size";

const colorOptions = {
  primary: {
    background: (props) => props.theme.green,
    title: (props) => props.theme.black,
  },
  secondary: {
    background: (props) => props.theme.green2,
    title: (props) => props.theme.green,
  },
};

const sizeOptions = {
  small: {
    width: "180px",
    height: "40px",
  },
  normal: {
    width: "205px",
    height: "40px",
  },
  large: {
    width: "290px",
    height: "44px",
  },
  full: {
    width: "100%",
    height: "44px",
  },
};

const sizeOptionsResize = {
  normal: {
    width: "205px",
    height: "40px",
  },
  large: {
    width: "290px",
    height: "44px",
  },
  full: {
    width: "100%",
    height: "44px",
  },
};

export const ButtonStyle = styled.button`
  border-radius: 2px;
  opacity: 1;
  background: transparent;
  font-family: "SpaceGrotesk";
  min-width: ${(props) => sizeOptions[props.size].width};
  height: ${(props) => sizeOptions[props.size].height};
  border: none;
  cursor: pointer;
  position: relative;

  @media only screen and (${breakpoints.mobile}) {
    min-width: ${(props) => sizeOptionsResize[props.sizeResponse].width};
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
    transform: skewX(-20deg);
    border: 1px solid rgba(191, 232, 45, 0.1) !important;
    background-color: ${(props) => colorOptions[props.color].background};
    z-index: 0;
  }

  svg {
    stroke: ${(props) => colorOptions[props.color].title};
    position: inherit;
    z-index: 9;
    height: ${(props) => sizeOptions[props.size].height};
  }

  h5 {
    opacity: 1;
    color: ${(props) => colorOptions[props.color].title};
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    position: inherit;
    z-index: 9;
  }

  @keyframes hvr-wobble-skew {
    16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg);
    }
    33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg);
    }
    49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg);
    }
    66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg);
    }
    83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg);
    }
    100% {
      -webkit-transform: skew(0);
      transform: skew(0);
    }
  }

  @media only screen and (${breakpoints.desktop}) {
    &:hover {
      // transform: scale(1.1);
      -webkit-animation-name: hvr-wobble-skew;
      animation-name: hvr-wobble-skew;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ImageLeft = styled.img`
  margin-right: 8px;
`;

export const ImageRight = styled.img`
  margin-left: 8px;
`;

const Button = ({
  background = "bluePrimary",
  size = "normal",
  sizeResponse = "normal",
  disabled = false,
  color = "primary",
  titleColor = "titlePrimary",
  left,
  right,
  title = "Log in",
  onClick,
  ...rest
}) => {
  return (
    <ButtonStyle
      {...rest}
      background={background}
      size={size}
      sizeResponse={sizeResponse}
      disabled={disabled}
      color={color}
      onClick={onClick}
    >
      {rest.children ? rest.children : <h5>{title}</h5>}
    </ButtonStyle>
  );
};

export default Button;

import React, { Children, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ButtonLink from "../link/ButtonLink";
import close from "../../images/ic_close.svg";
import { breakpoints } from "../../style/size";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Portal } from "../ParametersSelector/Portal";

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
    max-height: 100vh;
    min-height: 100vh;
    overflow: scroll;
  background-color: rgba(4, 8, 2, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
`;

export const FilterWrap = styled.div`
  border: 1px solid rgba(24, 31, 4, 1);
  box-shadow: 0px 3px 15px ${(props) => props.theme.green};
  opacity: 1;
  background-color: ${(props) => props.theme.black};
  width: 592px;
  height: auto;
  min-height: 100vh;
  float: right;
  z-index: 999;
  position: relative;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    float: unset;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
`;

export const FilterContent = styled.div`
  padding: 40px 60px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 20px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin: 20px 0 49px;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;
  }
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 22px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;
  }
`;

const Filter = ({ children, openFilter, setOpenFilter, onClick }) => {
  const handleClose = () => {
    setOpenFilter(!openFilter);
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current
        && !ref.current.contains(event.target)) {
        setOpenFilter(false);
      }
    };
    document.addEventListener(
      'click',
      handleClickOutside,
      true,
    );

    return () => {
      document.removeEventListener(
        'click',
        handleClickOutside,
        true,
      );
    };
  }, [])

  if (!openFilter) {
    return null
  }

  return (
    <Portal>
      <Overlay>
        <FilterWrap ref={ref}>
          <CloseIcon src={close} alt={close} onClick={handleClose} />
          <FilterContent>
            <TopContent>
              <h2>Filter</h2>
              <ButtonLink
                title="Clear"
                color={"gray"}
                underline
                onClick={onClick}
              />
            </TopContent>

            {children}
          </FilterContent>
        </FilterWrap>
      </Overlay>
    </Portal>

  );
};

export default Filter;

import React, { useEffect, useState } from "react";
import { Stage, Layer, Rect, Circle, Text } from "react-konva";
import styled from "styled-components";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../../components/Navigation/Navigation";
import IntroText from "../../../components/introText/index";

// Language
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  color: #333; /* Adjust color to match design */
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Index = () => {
  const [formationData, setFormationData] = useState(null);
    const [navigationOpen, setNavigationOpen] = useState(
      localStorage.getItem("navigationOpen")
    );

    useEffect(() => {
      const handleNavigationOpenChange = () => {
        setNavigationOpen(localStorage.getItem("navigationOpen"));
        console.log(localStorage.getItem("navigationOpen"), "ss");
      };

      window.addEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener(
          "navigationOpenChange",
          handleNavigationOpenChange
        );
      };
    }, []);

  useEffect(() => {
    // Static formation data
    const staticFormationData = {
      home: {
        team: "Home Team",
        formation: "4-4-2",
        players: [
          { name: "Home Player 1", position: "GK" },
          { name: "Home Player 2", position: "LB" },
          { name: "Home Player 3", position: "CB1" },
          { name: "Home Player 4", position: "CB2" },
          { name: "Home Player 5", position: "RB" },
          { name: "Home Player 6", position: "LM" },
          { name: "Home Player 7", position: "CM1" },
          { name: "Home Player 8", position: "CM2" },
          { name: "Home Player 9", position: "RM" },
          { name: "Home Player 10", position: "ST1" },
          { name: "Home Player 11", position: "ST2" },
        ],
      },
      away: {
        team: "Away Team",
        formation: "3-5-2",
        players: [
          { name: "Away Player 1", position: "GK" },
          { name: "Away Player 2", position: "CB1" },
          { name: "Away Player 3", position: "CB2" },
          { name: "Away Player 4", position: "CB3" },
          { name: "Away Player 5", position: "LM" },
          { name: "Away Player 6", position: "CM1" },
          { name: "Away Player 7", position: "CM2" },
          { name: "Away Player 8", position: "CM3" },
          { name: "Away Player 9", position: "RM" },
          { name: "Away Player 10", position: "ST1" },
          { name: "Away Player 11", position: "ST2" },
        ],
      },
    };

    setFormationData(staticFormationData);
  }, []);

  if (!formationData) {
    return <div>Loading...</div>;
  }

  const fieldWidth = 600;
  const fieldHeight = 400;
  const playerRadius = 15;

  const getPosition = (formation, index) => {
    const [def, mid, att] = formation.split("-").map(Number);
    const positions = [];

    for (let i = 0; i < def; i++) {
      positions.push({
        x: (i + 1) * (fieldWidth / (def + 1)),
        y: fieldHeight * 0.8,
      });
    }
    for (let i = 0; i < mid; i++) {
      positions.push({
        x: (i + 1) * (fieldWidth / (mid + 1)),
        y: fieldHeight * 0.5,
      });
    }
    for (let i = 0; i < att; i++) {
      positions.push({
        x: (i + 1) * (fieldWidth / (att + 1)),
        y: fieldHeight * 0.2,
      });
    }

    positions.push({ x: fieldWidth / 2, y: fieldHeight - 30 }); // Goalkeeper position

    return positions[index];
  };

  const renderFormation = (teamData, offsetX) => (
    <Layer>
      <Text
        x={offsetX + fieldWidth / 2 - 50}
        y={10}
        text={teamData.team}
        fontSize={20}
        fill="black"
      />
      <Rect
        x={offsetX}
        y={0}
        width={fieldWidth}
        height={fieldHeight}
        fill="#32CD32"
      />
      <Circle
        x={offsetX + fieldWidth / 2}
        y={fieldHeight / 2}
        radius={50}
        stroke="white"
        strokeWidth={2}
      />
      {teamData.players.map((player, index) => {
        const position = getPosition(teamData.formation, index);
        return (
          <React.Fragment key={index}>
            <Circle
              x={offsetX + position.x}
              y={position.y}
              radius={playerRadius}
              fill="blue"
            />
            <Text
              x={offsetX + position.x - playerRadius}
              y={position.y - playerRadius / 2}
              text={player.name}
              fontSize={12}
              fill="white"
            />
          </React.Fragment>
        );
      })}
    </Layer>
  );



  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle="Hello John"
            title="Check Out All Competitions."
          />
        </TopWrapper>
        <Title>Match Formation</Title>
        <Container>
          <Stage width={fieldWidth * 2 + 100} height={fieldHeight}>
            {renderFormation(formationData.home, 0)}
            {renderFormation(formationData.away, fieldWidth + 100)}
          </Stage>
        </Container>
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;

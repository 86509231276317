import React, { useEffect, useMemo, useRef, useState } from "react";
import { $apiV1, API_URL_V1 } from "../../config/api";
import { Tooltip as ReactTooltip } from "react-tooltip";


// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  TopTableNames,
  TableNameWrap,
  TableWrap,
  TableContent,
  Player,
  Position,
  Club,
  Passes,
  RightContent,
  SeeMoreWrap,
  Compare,
  ProfileImage,
  ImagesWrap,
  FilterButton,
  TableNameWrapSmrtSearch,
} from "../../style/SmrtSearch";

// Images
import emptyState from "../../images/contactLogo.svg";
import starOn from "../../images/star_on.svg";
import starOff from "../../images/star_off.svg";
import compareBtn from "../../images/ic_compare.svg";
import compareBlue from "../../images/compare_blue.svg";
import arrowDark from "../../images/arrow_dark.svg";
import arrowLight from "../../images/arrow_white.svg";
import arrowaUp from "../../images/up.svg";
import arrowDown from "../../images/down.svg";
import emptySearch from "../../images/ic_empty.svg";
import more from "../../images/ic_more.svg";
import searchIcon from "../../images/ic_search.svg";

import { ReactComponent as SortAscIcon } from "../../images/icons/sortasc_icon.svg";
import { ReactComponent as SortDescIcon } from "../../images/icons/sort_desc.svg";
import { ReactComponent as SortDefaultIcon } from "../../images/icons/sort_default.svg";

// Componenst
import Empty from "../empty/index";
import Filter from "../modal/Filter";
import Modal from "../../components/modal/index";
import Button from "../button";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageButton, PaginationWrapper } from "../../style/Favorites";
import { Oval } from "react-loader-spinner";
import { _calculateAge, extractFirstCharacters } from "../../helpers";
import {
  SelectedMatches,
  SelectedMatchesContent,
  SelectedMatchesOptions,
  SelectedMatchesOptionsContent,
} from "../../style/ComparePlayersStyle";
import {
  Action,
  Bottom,
  LeftTable,
  MatchContent,
  MatchInfo,
  MatchTableWrap,
  RightTable,
  Score,
  ScrollableContainer,
  StyledTable,
  TeamMatches,
  TeamName,
  TeamScore,
  Top,
} from "../../style/MatchTable";
import { EmptyState } from "../../style/Tournaments";
import Moment from "react-moment";
import {
  displayedParamsFielderIds,
  displayedsParamsGKIds,
} from "../../pages/smrtSearch";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Pagination } from "../ui";
import { StickyTableHead } from "./PlayerStat";

const SmrtSearchTable = (props) => {
  const {
    data,
    loading,
    isGkPosSelected,
    selectedMatch,
    handlePageChange,
    page,
    totalPages,
    totalPlayers,
  } = props;
  const navigate = useNavigate();

  const [favoritePlayers, setFavoritePlayers] = useState([]);

  const [openRow, setOpenRow] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);

  const [tableTypeSorting, setTableTypeSorting] = useState({ type: 'default', param: '' }); // type default | asc | desc param:number

  const availableTableNames = useMemo(() => {
    if (isGkPosSelected) {
      return displayedsParamsGKIds;
    }
    return displayedParamsFielderIds;
  }, [isGkPosSelected]);

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target === scrollRef1.current) {
        scrollRef2.current.scrollTop = e.target.scrollTop;
      } else if (e.target === scrollRef2.current) {
        scrollRef1.current.scrollTop = e.target.scrollTop;
      }
    };
    if (scrollRef1.current && scrollRef2.current) {
      scrollRef1.current.addEventListener('scroll', handleScroll);
      scrollRef2.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef1.current && scrollRef2.current) {
        scrollRef1.current.removeEventListener('scroll', handleScroll);
        scrollRef2.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  const setMoreInfo = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleCompare = (item) => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Check if the item ID is already in the array
    if (!playersArray.includes(item.id)) {
      // Add the new player ID to the array
      playersArray.push(item.id);
      // Store the updated array back in local storage
      localStorage.setItem("players", JSON.stringify(playersArray));
    }

    setSuccessPopup(!successPopup);
  };

  const getParamValue = (param) => {
    try {
      if (param?.count) {
        if (
          param?.parameter_name?.includes("%") ||
          (param?.parameter_id === 1 && selectedMatch === "Total")
        ) {
          return param.count.main;
        } else if (param?.parameter_id === 1) {
          return param?.count?.avg_matches;
        } else {
          const typeParam =
            selectedMatch === "Total"
              ? "main"
              : selectedMatch === "Average Match"
                ? "avg_matches"
                : "avg_90";
          return typeParam === "avg_matches" || typeParam === "avg_90"
            ? param?.count?.[typeParam]
            : param?.count?.[typeParam];
        }
      }
      return 0;
    } catch (e) {
      return param?.count?.main || "-";
    }
  };

  const modal = (
    <>
      {/* Success added to compare  */}
      <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
        <h1>Successfully added to compare</h1>
        <br />
        <Button
          title="Go to compare"
          size={"full"}
          onClick={() => navigate(`/compare`)}
        />
      </Modal>
    </>
  );

  const getSpecialSortedParams = (arr) => {
    try {
      const sortedArr = arr?.sort((a, b) => a.parameter_id - b.parameter_id);
      const assistIndex = sortedArr?.findIndex(
        (item) => item.parameter_id === 201
      );
      const goalIndex = sortedArr?.findIndex((item) => item.parameter_id === 2);
      if (goalIndex + 1 >= arr.length) {
        var k = goalIndex + 1 - arr.length + 1;
        while (k--) {
          sortedArr.push(undefined);
        }
      }
      sortedArr.splice(goalIndex + 1, 0, sortedArr.splice(assistIndex, 1)[0]);
      return sortedArr;
    } catch (e) {
      console.log("error special sort", e);
      return arr?.sort((a, b) => a.parameter_id - b.parameter_id);
    }
  };

  const handleSortTable = (param) => {
    if (tableTypeSorting.type === 'asc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'desc', param: param.parameter_id })
    } else if (tableTypeSorting.type === 'desc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'default', param: param.parameter_id })
    } else {
      setTableTypeSorting({ type: 'asc', param: param.parameter_id })
    }
  }

  const sortTable = (a, b) => {
    if (tableTypeSorting.param) {
      const paramA = a.params?.find(param => param.parameter_id === tableTypeSorting.param);
      const paramB = b.params?.find(param => param.parameter_id === tableTypeSorting.param);
      if (paramA && paramB) {
        const typeParam = selectedMatch === "Average Match"
          ? "avg_matches"
          : "avg_90";
        if (tableTypeSorting.type === 'asc') {
          return (paramA.count?.[typeParam] ?? paramA.count?.['main']) - (paramB.count?.[typeParam] ?? paramB.count?.['main']);
        }
        if (tableTypeSorting.type === 'desc') {
          return (paramB.count?.[typeParam] ?? paramB.count?.['main']) - (paramA.count?.[typeParam] ?? paramA.count?.['main']);
        }
      }
      if (paramA) return -1;
      if (paramB) return 1;
    }
    return 0
  }

  const sortedData = useMemo(() => {
    if (data?.length > 0) {
      return [...data]?.sort(sortTable)
    }
  }, [data, tableTypeSorting]);

  return (
    <>
      <MatchTableWrap className="smrtSearchTableWrap">
        <Oval
          secondaryColor={
            localStorage.getItem("theme") === "light" ? "#4E5255" : "#CFDAE6"
          }
          wrapperStyle={{
            width: "100%",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            display: loading ? 'flex' : 'none'
          }}
        />
        <div style={{ display: data?.length > 0 && !loading ? 'flex' : 'none' }}>
          <LeftTable className="smrtSearchLeftTable">
            <Top>
              <ul>
                <li>Player</li>
              </ul>
            </Top>
            <Bottom ref={scrollRef1} style={{ height: '62vh', overflow: 'hidden' }}>
              {sortedData?.map((player, index) => {
                const isFavorite = favoritePlayers[player.id] ?? false;
                return (
                  <TeamMatches key={player.id}>
                    <TeamName className="playerSmrtSearch">
                      <ImagesWrap>
                        <img
                          src={isFavorite ? starOn : starOff}
                          alt="Favorite star"
                          style={{
                            width: "18px",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleFavorite(player)}
                        />
                        <ProfileImage>
                          <img
                            style={{
                              width: "40px",
                              height: "40px;",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              window.open(
                                `/tournaments/player/${player.id}`,
                                "_blank"
                              )
                            }
                            src={
                              player.photos.length > 0
                                ? `https://platform.smrtstats.com:8888/${player.photos.find((photo) => photo.image)
                                  .image
                                }`
                                : emptyState
                            }
                            onError={(e) => {
                              e.target.src = emptyState;
                            }}
                            alt={"Player"}
                          />
                          {player?.nationality?.length > 0 && (
                            <img
                              className="flag"
                              src={
                                player?.nationality?.find((flag) => flag.flag)
                                  ?.flag
                              }
                              alt={"Flag"}
                              onError={(e) => {
                                e.target.src = emptyState;
                              }}
                            />
                          )}
                        </ProfileImage>
                      </ImagesWrap>

                      <Score>
                        <h3>
                          <span>Age {_calculateAge(new Date(player?.birth_date))}</span>
                          {/* <Moment format="DD.MM.YYYY">
                            {player?.birth_date}
                          </Moment> */}
                        </h3>
                        <h4
                          className="smrtSearchPlayer"
                          title={`${player.name} ${player.surname}`}
                          onClick={() =>
                            window.open(
                              `/tournaments/player/${player.id}`,
                              "_blank"
                            )
                          }
                        >
                          {player.name} {player.surname}
                        </h4>
                        <h3
                          className="smrtSearchPosition"
                          title={player.position
                            ?.map((pos) => pos.name)
                            ?.join("\r\n") || 'No position'}
                        >
                          {player.position
                            ?.map((pos) => pos.name)
                            ?.join(", ") || 'No position'}
                        </h3>
                        <span
                          title={player?.team?.name}
                          className="teamSmrtSearch"
                          onClick={() =>
                            player?.team?.id &&
                            window.open(
                              `/tournaments/team/${player.team?.id}`,
                              "_blank"
                            )
                          }
                        >
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                            }}
                            src={`https://platform.smrtstats.com:8888${player?.team?.logo}`}
                            onError={(e) => {
                              e.target.src = emptyState;
                            }}
                            alt={"Team logo"}
                          />
                          {player?.team?.name}
                        </span>
                      </Score>
                    </TeamName>
                    <TeamScore style={{ position: "relative" }}>
                      <img
                        className="more moreSmrtSearch"
                        src={more}
                        alt={more}
                        onClick={() => setMoreInfo(index)}
                      />
                      {openRow === index && (
                        <MatchInfo className="smrtSearchInfo">
                          <MatchContent>
                            <Action
                              onClick={() =>
                                window.open(
                                  `/tournaments/player/${player.id}`,
                                  "_blank"
                                )
                              }
                            >
                              <img src={searchIcon} alt={"See more"} />
                              <h4>See More</h4>
                            </Action>
                            <Action onClick={() => handleCompare(player)}>
                              <img
                                src={
                                  localStorage.getItem("theme") === "light"
                                    ? compareBlue
                                    : compareBtn
                                }
                                alt={"Compare"}
                              />
                              <h4>Compare</h4>
                            </Action>
                          </MatchContent>
                        </MatchInfo>
                      )}
                    </TeamScore>
                  </TeamMatches>
                );
              })}
            </Bottom>
          </LeftTable>
          <RightTable>
            <ScrollableContainer ref={scrollRef2} style={{ height: '65vh' }}>
              <StyledTable className="smrtSearchTable">
                <StickyTableHead>
                  <tr>
                    {getSpecialSortedParams(
                      data
                        ?.find((item) => item.params?.length > 0)
                        ?.params?.filter((item) =>
                          availableTableNames.includes(item.parameter_id)
                        )
                    )?.map((param) => {
                      // Create a shorthand based on the first letters of the parameter name
                      const shorthand = param.parameter_name
                        .split(" ")
                        .filter((word) => word.length > 0) // Filter out any empty strings
                        .map((word) => word[0].toUpperCase())
                        .join("");

                      return (
                        <th
                          key={param.parameter_id}
                          data-tooltip-id={`name-${param.parameter_id}`}
                          className="smrtSearchTh"
                          onClick={() => handleSortTable(param)}
                        >
                          {shorthand}
                          {
                            tableTypeSorting.type === 'asc' && tableTypeSorting.param === param.parameter_id ? (
                              <SortAscIcon />
                            ) : tableTypeSorting.type === 'desc' && tableTypeSorting.param === param.parameter_id ? (
                              <SortDescIcon />
                            ) : (
                              <SortDefaultIcon />
                            )
                          }
                          <ReactTooltip
                            id={`name-${param.parameter_id}`}
                            place="bottom"
                            content={param.parameter_name}
                          />
                        </th>
                      );
                    })}
                  </tr>
                </StickyTableHead>

                <tbody className="smrtSearchTable">
                  {sortedData?.map((player) => {
                    return (
                      <tr key={player.id}>
                        {getSpecialSortedParams(
                          player.params?.filter((item) =>
                            availableTableNames.includes(item.parameter_id)
                          )
                        )?.map((param) => {
                          return (
                            <td
                              className="smrtSearchTd"
                              key={param.parameter_id}
                            // style={{
                            //   cursor:
                            //     param.markers !== "" ? "pointer" : "",
                            // }}
                            // onClick={() =>
                            //   navigate(
                            //     `/tournaments/match/video?markers=${param.markers?.join(
                            //       ","
                            //     )}`
                            //   )
                            // }
                            >
                              {getParamValue(param)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
            </ScrollableContainer>
          </RightTable>
        </div>
        <EmptyState style={{ display: data?.length === 0 && !loading ? 'flex' : 'none', height: '50vh' }}>
          <img src={emptySearch} alt={emptySearch} />
          <h3>No results for players</h3>
        </EmptyState>
        {/* {loading ? (
          <Oval
            secondaryColor={
              localStorage.getItem("theme") === "light" ? "#4E5255" : "#CFDAE6"
            }
            wrapperStyle={{
              width: "100%",
              height: "60vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : data?.length > 0 ? (
          <>
            <LeftTable className="smrtSearchLeftTable">
              <Top>
                <ul>
                  <li>Player</li>
                </ul>
              </Top>
              <Bottom ref={scrollRef1} style={{ height: '62vh', overflow: 'hidden' }}>
                {sortedData?.map((player, index) => {
                  const isFavorite = favoritePlayers[player.id] ?? false;
                  return (
                    <TeamMatches key={player.id}>
                      <TeamName className="playerSmrtSearch">
                        <ImagesWrap>
                          <img
                            src={isFavorite ? starOn : starOff}
                            alt="Favorite star"
                            style={{
                              width: "18px",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleFavorite(player)}
                          />
                          <ProfileImage>
                            <img
                              style={{
                                width: "40px",
                                height: "40px;",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(
                                  `/tournaments/player/${player.id}`,
                                  "_blank"
                                )
                              }
                              src={
                                player.photos.length > 0
                                  ? `https://platform.smrtstats.com:8888/${player.photos.find((photo) => photo.image)
                                    .image
                                  }`
                                  : emptyState
                              }
                              onError={(e) => {
                                e.target.src = emptyState;
                              }}
                              alt={"Player"}
                            />
                            {player?.nationality?.length > 0 && (
                              <img
                                className="flag"
                                src={
                                  player?.nationality?.find((flag) => flag.flag)
                                    ?.flag
                                }
                                alt={"Flag"}
                                onError={(e) => {
                                  e.target.src = emptyState;
                                }}
                              />
                            )}
                          </ProfileImage>
                        </ImagesWrap>

                        <Score>
                          <h3>
                            <Moment format="DD.MM.YYYY">
                              {player?.birth_date}
                            </Moment>
                          </h3>
                          <h4
                            className="smrtSearchPlayer"
                            title={`${player.name} ${player.surname}`}
                            onClick={() =>
                              window.open(
                                `/tournaments/player/${player.id}`,
                                "_blank"
                              )
                            }
                          >
                            {player.name} {player.surname}
                          </h4>
                          <h3
                            className="smrtSearchPosition"
                            title={player.position
                              ?.map((pos) => pos.name)
                              ?.join("\r\n")}
                          >
                            {player.position
                              ?.map((pos) => pos.name)
                              ?.join(", ")}
                          </h3>
                          <span
                            title={player?.team?.name}
                            className="teamSmrtSearch"
                            onClick={() =>
                              player?.team?.id &&
                              window.open(
                                `/tournaments/team/${player.team?.id}`,
                                "_blank"
                              )
                            }
                          >
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                objectFit: "contain",
                              }}
                              src={`https://platform.smrtstats.com:8888${player?.team?.logo}`}
                              onError={(e) => {
                                e.target.src = emptyState;
                              }}
                              alt={"Team logo"}
                            />
                            {player?.team?.name}
                          </span>
                        </Score>
                      </TeamName>
                      <TeamScore style={{ position: "relative" }}>
                        <img
                          className="more moreSmrtSearch"
                          src={more}
                          alt={more}
                          onClick={() => setMoreInfo(index)}
                        />
                        {openRow === index && (
                          <MatchInfo className="smrtSearchInfo">
                            <MatchContent>
                              <Action
                                onClick={() =>
                                  window.open(
                                    `/tournaments/player/${player.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                <img src={searchIcon} alt={"See more"} />
                                <h4>See More</h4>
                              </Action>
                              <Action onClick={() => handleCompare(player)}>
                                <img
                                  src={
                                    localStorage.getItem("theme") === "light"
                                      ? compareBlue
                                      : compareBtn
                                  }
                                  alt={"Compare"}
                                />
                                <h4>Compare</h4>
                              </Action>
                            </MatchContent>
                          </MatchInfo>
                        )}
                      </TeamScore>
                    </TeamMatches>
                  );
                })}
              </Bottom>
            </LeftTable>
            <RightTable>
              <ScrollableContainer  ref={scrollRef2} style={{ height: '65vh' }}>
                <StyledTable className="smrtSearchTable">
                  <StickyTableHead>
                    <tr>
                      {getSpecialSortedParams(
                        data
                          ?.find((item) => item.params?.length > 0)
                          ?.params?.filter((item) =>
                            availableTableNames.includes(item.parameter_id)
                          )
                      )?.map((param) => {
                        // Create a shorthand based on the first letters of the parameter name
                        const shorthand = param.parameter_name
                          .split(" ")
                          .filter((word) => word.length > 0) // Filter out any empty strings
                          .map((word) => word[0].toUpperCase())
                          .join("");

                        return (
                          <th
                            key={param.parameter_id}
                            data-tooltip-id={`name-${param.parameter_id}`}
                            className="smrtSearchTh"
                            onClick={() => handleSortTable(param)}
                          >
                            {shorthand}
                            {
                              tableTypeSorting.type === 'asc' && tableTypeSorting.param === param.parameter_id ? (
                                <SortAscIcon />
                              ) : tableTypeSorting.type === 'desc' && tableTypeSorting.param === param.parameter_id ? (
                                <SortDescIcon />
                              ) : (
                                <SortDefaultIcon />
                              )
                            }
                            <ReactTooltip
                              id={`name-${param.parameter_id}`}
                              place="bottom"
                              content={param.parameter_name}
                            />
                          </th>
                        );
                      })}
                    </tr>
                  </StickyTableHead>

                  <tbody className="smrtSearchTable">
                    {sortedData?.map((player) => {
                      return (
                        <tr key={player.id}>
                          {getSpecialSortedParams(
                            player.params?.filter((item) =>
                              availableTableNames.includes(item.parameter_id)
                            )
                          )?.map((param) => {
                            return (
                              <td
                                className="smrtSearchTd"
                                key={param.parameter_id}
                              // style={{
                              //   cursor:
                              //     param.markers !== "" ? "pointer" : "",
                              // }}
                              // onClick={() =>
                              //   navigate(
                              //     `/tournaments/match/video?markers=${param.markers?.join(
                              //       ","
                              //     )}`
                              //   )
                              // }
                              >
                                {getParamValue(param)}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </StyledTable>
              </ScrollableContainer>
            </RightTable>
          </>
        ) : (
          <EmptyState>
            <img src={emptySearch} alt={emptySearch} />
            <h3>No results for matches</h3>
          </EmptyState>
        )} */}
      </MatchTableWrap>
      <Pagination
        disabled={loading}
        items={totalPlayers}
        itemsPerPage={10}
        page={page - 1 || 0}
        onPageChange={handlePageChange}
      />
      {/* <PaginationWrapper>
        <PageButton
          disabled={loading}
          onClick={() => handlePageChange(page - 1)}
        >
          <img
            src={
              localStorage.getItem("theme") === "light" ? arrowLight : arrowDark
            }
            alt={arrowDark}
            className="leftTransform"
          />
        </PageButton>
        <span>
          {page} of {totalPages.toLocaleString()}
        </span>
        <PageButton
          disabled={loading}
          onClick={() => handlePageChange(page + 1)}
        >
          <img
            className="rightTransform"
            src={
              localStorage.getItem("theme") === "light" ? arrowLight : arrowDark
            }
            alt={arrowDark}
          />
        </PageButton>
      </PaginationWrapper> */}
      {modal}
    </>
  );
};

const SmrtSearchSidebar = ({
  table,
  page,
  totalPages,
  onPageChange,
  loadingSearch,
  isGkPosSelected,
  totalPlayers,
}) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t, i18n } = useTranslation();

  const [openMatches, setOpenMatches] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState("Average Match");

  // State to track favorite status for each player by their ID
  const [favoritePlayers, setFavoritePlayers] = useState({});

  const selectedMatchList = [
    {
      id: 1,
      name: "Total",
    },
    {
      id: 2,
      name: "Average Match",
    },
    // Strange results from the server. Uncomment when fixed.
    // {
    //   id: 3,
    //   name: "Average 90",
    // },
  ];

  const handleClickedMatch = (item) => {
    setSelectedMatch(item.name);
    setOpenMatches(!openMatches);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const navigate = useNavigate();

  const tableNamesGk = useMemo(
    () => [
      {
        id: 1,
        name: `${t("smrtSearch.player")}`,
      },
      {
        id: 2,
        name: `${t("smrtSearch.position")}`,
      },
      {
        id: 3,
        name: `${t("smrtSearch.club")}`,
      },
      {
        id: 4,
        name: `Mins Played`,
      },
      {
        id: 5,
        name: `Aerial Duels + (%)`,
      },
      {
        id: 6,
        name: `Clean Sheets`,
      },
      {
        id: 7,
        name: `Effective Saves`,
      },
    ],
    [t]
  );

  const tableNamesFielder = useMemo(
    () => [
      {
        id: 1,
        name: `${t("smrtSearch.player")}`,
      },
      {
        id: 2,
        name: `${t("smrtSearch.position")}`,
      },
      {
        id: 3,
        name: `${t("smrtSearch.club")}`,
      },
      {
        id: 4,
        name: `Mins Played`,
      },
      {
        id: 5,
        name: `Aerial Duels + %`,
      },
      {
        id: 6,
        name: `${t("smrtSearch.passes")}`,
      },
      {
        id: 7,
        name: `Passes + %`,
      },
      {
        id: 8,
        name: `${t("smrtSearch.goals")}`,
      },
      {
        id: 9,
        name: `${t("smrtSearch.assists")}`,
      },
      {
        id: 10,
        name: `${t("smrtSearch.mist")}`,
      },
      {
        id: 11,
        name: `Duels + %`,
      },
      {
        id: 12,
        name: `Crosses`,
      },
      {
        id: 13,
        name: `Interceptions +`,
      },
      {
        id: 14,
        name: `Ball Recoveries`,
      },
      {
        id: 15,
        name: `Dribbles Made +, %`,
      },
      {
        id: 16,
        name: `Tackles Made`,
      },
      {
        id: 17,
        name: "Shots On Target, %",
      },
    ],
    [t]
  );

  const tableNames = useMemo(() => {
    if (isGkPosSelected) {
      return tableNamesGk;
    }
    return tableNamesFielder;
  }, [isGkPosSelected, tableNamesFielder, tableNamesGk]);

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  const [successPopup, setSuccessPopup] = useState(false);

  const handleCompare = (item) => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Check if the item ID is already in the array
    if (!playersArray.includes(item.id)) {
      // Add the new player ID to the array
      playersArray.push(item.id);
      // Store the updated array back in local storage
      localStorage.setItem("players", JSON.stringify(playersArray));
    }

    setSuccessPopup(!successPopup);
  };

  const getParamValue = (param) => {
    try {
      if (param?.count) {
        if (
          param?.parameter_name?.includes("%") ||
          (param?.parameter_id === 1 && selectedMatch === "Total")
        ) {
          return Math.round(param.count.main);
        } else if (param?.parameter_id === 1) {
          return Math.round(param?.count?.avg_matches);
        } else {
          const typeParam =
            selectedMatch === "Total"
              ? "main"
              : selectedMatch === "Average Match"
                ? "avg_matches"
                : "avg_90";
          return typeParam === "avg_matches" || typeParam === "avg_90"
            ? Math.ceil(param?.count?.[typeParam])
            : Math.round(param?.count?.[typeParam]);
        }
      }
      return 0;
    } catch (e) {
      return Math.round(param?.count?.main) || "-";
    }
  };

  const modal = (
    <>
      {/* Success added to compare  */}
      <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
        <h1>Successfully added to compare</h1>
        <br />
        <Button
          title="Go to compare"
          size={"full"}
          onClick={() => navigate(`/compare`)}
        />
      </Modal>
    </>
  );

  return (
    <>
      <SelectedMatchesContent style={{ maxWidth: 300, marginBottom: 20 }}>
        <SelectedMatches onClick={() => setOpenMatches(!openMatches)}>
          <h3>{selectedMatch ?? "Selected Match"}</h3>
          <img
            src={openMatches ? arrowaUp : arrowDown}
            alt={openMatches ? arrowaUp : arrowDown}
          />
        </SelectedMatches>

        {openMatches && (
          <SelectedMatchesOptions>
            <SelectedMatchesOptionsContent>
              <ul>
                {selectedMatchList.map((item, index) => {
                  return (
                    <li key={index} onClick={() => handleClickedMatch(item)}>
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </SelectedMatchesOptionsContent>
          </SelectedMatchesOptions>
        )}
      </SelectedMatchesContent>

      <SmrtSearchTable
        data={table}
        loading={loadingSearch}
        selectedMatch={selectedMatch}
        page={page}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        isGkPosSelected={isGkPosSelected}
        totalPlayers={totalPlayers}
      />
      { }
    </>
  );
};

export default SmrtSearchSidebar;

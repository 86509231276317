import React, { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import Filter, { FormWrap } from "../modal/Filter";
import Button from "../button/index";

// Images
import playEmpty from "../../images/ic_play-empty.svg";
import play from "../../images/ic_play.svg";
import playMatchBlue from "../../images/play_match_blue.svg";
import playActive from "../../images/ic_play_active.svg";
import playActiveBlue from "../../images/ic_play_active_blue.svg";
import playinball from "../../images/ic_playinball.svg";
import playInBallBlue from "../../images/playInBall_blue.svg";
import statistics from "../../images/ic_statistics.svg";
import statisticsBlue from "../../images/statistics_blue.svg";
import formation from "../../images/football-field.svg";
import formationBlue from "../../images/football-field-blue.svg";
import moreDots from "../../images/ic_more.svg";
import playInBall from "../../images/ic_playinball.svg";

import download from "../../images/ic_download.svg";
import download_blue from "../../images/download_blue.svg";

// Componenst
import Empty from "../../components/empty/index";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Language
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Moment from "react-moment";
import { breakpoints } from "../../style/size";
import {
  Action,
  ButtonFilter,
  MatchContent,
  MatchInfo,
} from "../../style/MatchTable";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import TextInput, { InputWrapper } from "../inputs/TextInput";
import { $apiV1 } from "../../config/api";
import { Formation } from "../Formation";
import { NotificationList } from "../notification/NotificationList";
import { useNotification } from "../notification/useNotification";
import { getTeamParamMarkers } from "../../helpers";
import { useDispatch } from "react-redux";
import { downloadVideosActions } from "../../store/download_videos/downloadVideosSlice";
import { TopCardInfo, WrappContent } from "../../style/Overview";
import useOutsideClick from "../../hooks/useOutsideClick";

export const MatchWrap = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  // height: 471px;
  height: fit-content;
  margin-bottom: 30px;
`;

export const TeamStats = styled.div`
  display: flex;
  flex-direction: row;

  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }
`;

export const TeamScore = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

export const TeamOptions = styled.div`
  min-width: 200px;
  width: 20%;
  background: ${(props) => props.theme.lines};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 0 10px;

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const Home = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 0 40px;

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  &.awayTeam {
    justify-content: end;
  }

  img {
    width: 40px;
    height: 40px;
  }

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    max-width: 13vw;
    // width: 15vw;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h4.teamPageName {
    max-width: 10vw;
  }

  @media screen and (max-width: 1880px) {
    h4.teamPageName {
      max-width: 9vw;
    }
  } 

  @media screen and (max-width: 1780px) {
    h4.teamPageName {
      max-width: 8vw;
    }
  } 

  @media screen and (max-width: 1520px) {
    h4.teamPageName {
      max-width: 7vw;
    }
  } 

  @media screen and (max-width: 1455px) {
    h4.teamPageName {
      max-width: 6vw;
    }
  } 

  @media screen and (max-width: 1370px) {
    h4.teamPageName {
      max-width: 5vw;
    }
  } 

  @media screen and (max-width: 1300px) {
    h4.teamPageName {
      max-width: 13vw;
    }
  } 
`;

export const Score = styled.div`
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.scorePlayerStat {
    width: 100%;
    text-align: left;
    align-items: flex-start;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: normal;
    margin-top: 10px;
    display: flex;

    & > span {
      opacity: 1;
      color: ${(props) => props.theme.white};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: normal;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &.playerStatTitle {
      color: ${(props) => props.theme.white};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 105%;
    }
  }

  h3 {
    line-height: normal;
    display: flex;
    align-items: center;
    jusify-content: center;

    time {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  h4 {
    margin-top: 10px;
    opacity: 1;
    font-family: "SatoshiBold";
    font-size: 20px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 2px;
    text-align: center;
    line-height: 28px;
    color: ${(props) => props.theme.white};
  }

  span {
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.green};
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 15vw;
    white-space: nowrap;
  }
`;

export const TeamScoreMobile = styled.div`
  padding: 10px;
  width: 100%;
  position: relative;

  h3 {
    opacity: 1;
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 18px;
    time {
      font-family: "SatoshiMedium";
      color: rgba(115, 128, 137, 1);
    }
  }
`;

export const TopFunction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    transform: rotate(90deg);
    margin-right: 5px;
  }

  h3 > span {
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.green};
    margin-left: 10px;
  }
`;

export const TeamScoreMobileContent = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
export const HomeResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
    color: ${(props) => props.theme.white};
  }
`;
export const AwayResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
    color: ${(props) => props.theme.white};
  }
`;
export const TeamMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: -40px;
  margin-bottom: 20px;
`;

export const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  max-height: 200px;
  overflow: scroll;
  border: 1px solid ${(props) => props.theme.borderColor};

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.black};
    }
  }
`;

const Matches = ({
  table,
  onChangeData,
  season,
  playerTeams,
  styleTableWrapper = {},
  showTitle,
  type = 'player',
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [openRow, setOpenRow] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [choosenSeasson, setChoosenSeasson] = useState(season);
  const [availableSeasons, setAvailableSeason] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [filteredMatches, setFilteredMatches] = useState(table);
  const [mathcesList, setMatchesList] = useState("");
  const [lastMatches, SetLastMatches] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFilteredMatches(table);
  }, [table])

  const dispatch = useDispatch();

  //formation state
  const [openFormation, setOpenFormation] = useState(false);
  const [selectedFormationMatch, setSelectedFormationMatch] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);
  const [openMatchesCount, setOpenMatchesCount] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const [selectedMatch, setSelectedMatch] = useState("");

  const { notifications, addNotification, removeNotification } =
    useNotification();

  const handleCloseFormation = () => {
    setOpenFormation(false);
    setSelectedFormationMatch(null);
  };

  const handleOpenFormation = (match) => {
    setSelectedFormationMatch(match);
    setOpenFormation(true);
  };

  const handleClearFilter = () => {
    setChoosenSeasson();
    setSelectedSeason("");
    setCompetitions();
    setSelectedCompetition("");
  };

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setSelectedCompetition("");
    setChoosenCompetition("");
    setOpen(false);
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(item.competition_id);
    setSelectedCompetition(item.competition);
    setOpenCompetition(false);
  };

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const setMoreInfo = (item) => {
    setOpenRow(openRow === item.id ? null : item.id);
  };

  const handleSeasons = async () => {
    try {
      setLoading(true);
      let response;
      if (type === 'team') {
        response = await $apiV1.get(
          `/statistics/team/${id}?year_season_id=${choosenSeasson}`
        );
      } else {
        response = await $apiV1.get(
          `/statistics/player/${id}?year_season_id=${choosenSeasson}`
        );
      }

      setAvailableSeason(response.data.available_seasons);
      setCompetitions(response.data.matches_list);

      const filteredMatches = response?.data?.matches_list
        ?.filter((match) =>
          choosenCompetition
            ? match.competition_id === choosenCompetition
            : true
        )
        .map((match) => match.id);

      setMatchesList(filteredMatches);
      if (type === 'team') {
        setFilteredMatches(
          response?.data?.matches_list
            ?.filter((match) =>
              choosenCompetition
                ? match.competition_id === choosenCompetition
                : true
            )
        )
        onChangeData?.(response?.data, choosenSeasson);
        return
      } else {
        onChangeData?.(response.data, choosenSeasson);
      }
      // Filter matches to show based on the updated list
      // const matchesToShow = response.data.matches_list.filter((match) =>
      //   filteredMatches.includes(match.id)
      // );
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSeasons();
  }, [choosenSeasson, choosenCompetition]);

  const uniqueCompetitions = [];
  const seenNames = new Set();

  competitions?.forEach((item) => {
    if (!seenNames.has(item.competition)) {
      seenNames.add(item.competition);
      uniqueCompetitions.push(item);
    }
  });

  const handleSearch = async () => {
    if (mathcesList?.length > 0) {
      const updatedFilteredMatches = table.filter((match) =>
        mathcesList.includes(match.id)
      );
      setFilteredMatches(updatedFilteredMatches); // Update filtered matches
    }
    setOpenFilter(false);
  };

  const matches = [
    {
      id: 1,
      value: filteredMatches?.length,
      title: "All matches",
    },
    {
      id: 2,
      value: 3,
      title: "Last 3 matches",
    },
    {
      id: 3,
      value: 5,
      title: "Last 5 matches",
    },
    {
      id: 4,
      value: 10,
      title: "Last 10 matches",
    },
  ];

  const currentSelectedSeason = useMemo(() => {
    if (selectedSeason) return selectedSeason;
    if (availableSeasons?.length) {
      const foundedSeason = availableSeasons?.find((item) => item.id == season);
      if (season && foundedSeason) {
        return foundedSeason?.years;
      }
    }
    return "Select Season";
  }, [season, selectedSeason, availableSeasons]);

  const getMatchMarkersListTeam = async (matchId, parameter) => {
    try {
      const response = await $apiV1.get(`/stats/team/${id}`, {
        params: {
          matches: matchId,
          params: parameter,
        },
      });

      const allMarkers = response.data?.stats?.flatMap((stat) =>
        stat.parameters.flatMap((param) => param.markers)
      );
      return Promise.resolve(allMarkers);
    } catch (error) {
      console.error("Error fetching data:", error);
      return Promise.reject(error);
    }
  };

  const getMatchMarkersListPlayer = async (matchId, parameter) => {
    try {
      const response = await $apiV1.get(`stats/player/${id}`, {
        params: {
          matches: matchId,
          params: parameter,
        },
      });

      const allMarkers = response.data?.stats?.flatMap((stat) =>
        stat.parameters.flatMap((param) => param.markers)
      );
      return Promise.resolve(allMarkers);
    } catch (error) {
      console.error("Error fetching data:", error);
      return Promise.reject(error);
    }
  };

  const handleClickMatchParam = async (param_id, match, new_tab) => {
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add('loading-cursor');
      const data = type === 'player' ? await getMatchMarkersListPlayer(match?.id, param_id) : await getMatchMarkersListTeam(match?.id, param_id);

      if (Array.isArray(data) && data?.length > 0) {
        const markers = data?.join(",");
        new_tab
          ? window.open(`/tournaments/match/video?title=On Ball Events - ${match?.home_team} ${match?.away_team}&markers=${markers}`, '_blank')
          : navigate(`/tournaments/match/video?title=On Ball Events - ${match?.home_team} ${match?.away_team}&markers=${markers}`)
      } else {
        addNotification(
          `There are 0 clips in the response from the server.`,
          "warning"
        );
      }
    } catch (e) {
      console.log("error get param markers", e);
      if (e.type === "canceled") {
        addNotification(
          `The request to receive clips of the match has been canceled.`,
          "warning"
        );
        return;
      }
      addNotification(`Error in receiving match events. ${e.message}`, "error");
    } finally {
      document.body.classList.remove("loading-cursor");
    }
  };

  const getColorScore = (item) => {

    const isTeamHome = playerTeams
      ?.map((item) => item.name)
      ?.includes(item.home_team);
    if (isTeamHome) {
      return item.home_team_score > item.away_team_score
        ? "#36E82D"
        : item.home_team_score < item.away_team_score
          ? "#F20A46"
          : "#E8D02D";
    } else {
      return item.home_team_score > item.away_team_score
        ? "#F20A46"
        : item.home_team_score < item.away_team_score
          ? "#36E82D"
          : "#E8D02D";
    }
  };

  const seasonDropdownRef = useOutsideClick(() => {
    if (open) {
      setOpen(false);
    }
  });

  const competitionDropdownRef = useOutsideClick(() => {
    if (openCompetition) {
      setOpenCompetition(false);
    }
  });

  const mathesCountDropdownRef = useOutsideClick(() => {
    if (openMatchesCount) {
      setOpenMatchesCount(false);
    }
  });

  return (
    <>
      <div>
        {showTitle && (
          <WrappContent>
            <h2>Matches</h2>
          </WrappContent>
        )}
        <ButtonWrapper style={showTitle ? { marginBottom: 5 } : {}}>
          <ButtonFilter
            className="buttonFilter"
            onClick={() => setOpenFilter(!openFilter)}
          >
            {t("smrtSearch.filter")}
          </ButtonFilter>
        </ButtonWrapper>
        {
          table?.length > 0 ? (
            <MatchWrap style={styleTableWrapper}>
              {filteredMatches
                ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                ?.slice(0, lastMatches ? lastMatches : table?.length)
                .map((item, index) => {
                  return (
                    <TeamStats key={item.id}>
                      {!isMobile && (
                        <TeamScore>
                          <Home
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.open(`/tournaments/team/${item?.home_team_id}`)
                            }}
                          >
                            <img
                              src={item?.home_team_logo}
                              alt={item?.home_team_logo}
                            />{" "}
                            <h4 className={type === 'team' ? 'teamPageName' : ''} data-tooltip-id={`full-name-${item?.home_team}-${item.id}`}>
                              {item?.home_team}
                            </h4>
                            <ReactTooltip
                              id={`full-name-${item.home_team}-${item.id}`}
                              place="bottom"
                              content={item?.home_team}
                            />
                          </Home>
                          <Score>
                            {Boolean(item?.mins_played) && (
                              <span>Minutes played {item?.mins_played}</span>
                            )}
                            <h3>
                              <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                            </h3>
                            <h4
                              style={{
                                color: getColorScore(item),
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                window.open(
                                  `/tournaments/statistics/${item.id}`,
                                  "_blank"
                                )
                              }}
                            >
                              {item.score}
                            </h4>
                          </Score>
                          <Home
                            className="awayTeam"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.open(`/tournaments/team/${item?.away_team_id}`)
                            }}
                          >
                            <img
                              src={item?.away_team_logo}
                              alt={item?.away_team_logo}
                            />{" "}
                            <h4 className={type === 'team' ? 'teamPageName' : ''} data-tooltip-id={`full-name-${item?.away_team}-${item.id}`}>
                              {item?.away_team}
                            </h4>
                            <ReactTooltip
                              id={`full-name-${item.away_team}-${item.id}`}
                              place="bottom"
                              content={item?.away_team}
                            />
                          </Home>
                        </TeamScore>
                      )}
                      {isMobile && (
                        <TeamScoreMobile>
                          <TeamScoreMobileContent>
                            <TopFunction>
                              {" "}
                              <h3>
                                <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                                {Boolean(item?.mins_played) && (
                                  <span>Minutes played {item?.mins_played}</span>
                                )}
                              </h3>

                              <img
                                src={moreDots}
                                alt={moreDots}
                                onClick={() => setMoreInfo(item)}
                              />
                            </TopFunction>
                            <HomeResult>
                              <TeamMobile
                                onClick={() => {
                                  window.open(`/tournaments/team/${item?.home_team_id}`)
                                }}
                              >
                                <img
                                  src={item?.home_team_logo}
                                  alt={item?.home_team_logo}
                                />{" "}
                                <h4>{item?.home_team}</h4>
                              </TeamMobile>
                              {openRow === item.id && (
                                <MatchInfo style={{ top: "30%", right: "10px" }}>
                                  <MatchContent>
                                    <Action
                                      onClick={() => {
                                        if (item?.videos?.length > 0) {
                                          window.open(
                                            `/tournaments/match/video/${item.id}`,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.videos?.length > 0
                                            ? localStorage.getItem("theme") ===
                                              "light"
                                              ? playActiveBlue
                                              : playActive
                                            : playEmpty
                                        }
                                        alt={
                                          localStorage.getItem("theme") ===
                                            "light"
                                            ? playMatchBlue
                                            : play
                                        }
                                      />
                                      <h4>Play Full Match</h4>
                                    </Action>
                                    <Action
                                      onClick={() =>
                                        handleClickMatchParam(14, item)
                                      }
                                    >
                                      <img
                                        src={
                                          localStorage.getItem("theme") ===
                                            "light"
                                            ? playInBallBlue
                                            : playInBall
                                        }
                                        alt={playInBall}
                                      />
                                      <h4>OBE</h4>
                                    </Action>
                                    <Action
                                      onClick={() =>
                                        window.open(
                                          `/tournaments/statistics/${item.id}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          localStorage.getItem("theme") ===
                                            "light"
                                            ? statisticsBlue
                                            : statistics
                                        }
                                        alt={statistics}
                                      />
                                      <h4>See Match Statistics</h4>
                                    </Action>
                                    <Action
                                      onClick={() => {
                                        handleOpenFormation(item);
                                      }}
                                    >
                                      <img
                                        src={
                                          localStorage.getItem("theme") ===
                                            "light"
                                            ? formationBlue
                                            : formation
                                        }
                                        alt={formation}
                                      />
                                      <h4>See Match Formation</h4>
                                    </Action>

                                    {item.videos[0].link && (
                                      <Action
                                        onClick={() => {
                                          dispatch(
                                            downloadVideosActions.addVideoLink(
                                              item.videos[0].link
                                            )
                                          );
                                          addNotification(
                                            "Please do not close the tab while the download is in progress.",
                                            "info"
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            localStorage.getItem("theme") ===
                                              "light"
                                              ? download_blue
                                              : download
                                          }
                                          alt={download}
                                        />
                                        <h4>Download Full Match</h4>
                                      </Action>
                                    )}
                                  </MatchContent>
                                </MatchInfo>
                              )}

                              <h5
                                style={{
                                  color: getColorScore(item),
                                }}
                                onClick={() => {
                                  window.open(
                                    `/tournaments/statistics/${item.id}`,
                                    "_blank"
                                  )
                                }}
                              >
                                {item?.home_team_score}
                              </h5>
                            </HomeResult>
                            <AwayResult>
                              <TeamMobile
                                onClick={() => {
                                  window.open(`/tournaments/team/${item?.away_team_id}`)
                                }}
                              >
                                <img
                                  src={item?.away_team_logo}
                                  alt={item?.away_team_logo}
                                />{" "}
                                <h4>{item?.away_team}</h4>
                              </TeamMobile>
                              <h5
                                style={{
                                  color: getColorScore(item),
                                }}
                                onClick={() => {
                                  window.open(
                                    `/tournaments/statistics/${item.id}`,
                                    "_blank"
                                  )
                                }}
                              >
                                {item?.away_team_score}
                              </h5>
                            </AwayResult>
                          </TeamScoreMobileContent>
                        </TeamScoreMobile>
                      )}
                      {!isMobile && (
                        <TeamOptions>
                          <img
                            src={
                              item?.videos?.length > 0
                                ? localStorage.getItem("theme") === "light"
                                  ? playActiveBlue
                                  : playActive
                                : playEmpty
                            }
                            alt={play}
                            onClick={() => {
                              if (item?.videos?.length > 0) {
                                navigate(`/tournaments/match/video/${item?.id}`);
                              }
                            }}
                            onContextMenu={() => {
                              if (item?.videos?.length > 0) {
                                window.open(
                                  `/tournaments/match/video/${item.id}`,
                                  "_blank"
                                );
                              }
                            }}
                            style={{
                              cursor: item?.videos?.length > 0 ? 'pointer' : 'none',
                              pointerEvents: item?.videos?.length > 0 ? 'auto' : 'none',
                            }}
                            data-tooltip-id={`play-full-match-${item.id}`}
                          />
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playInBallBlue
                                : playinball
                            }
                            alt={playinball}
                            data-tooltip-id={`play-ball-in-play-${item.id}`}
                            onClick={() => handleClickMatchParam(14, item)}
                            onContextMenu={() =>
                              handleClickMatchParam(14, item, true)
                            }
                          />

                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? statisticsBlue
                                : statistics
                            }
                            alt={statistics}
                            onClick={() =>
                              navigate(`/tournaments/statistics/${item.id}`)
                            }
                            onContextMenu={() =>
                              window.open(
                                `/tournaments/statistics/${item.id}`,
                                "_blank"
                              )
                            }
                            style={{ cursor: "pointer" }}
                            data-tooltip-id={`see-match-statistics-${item.id}`}
                          />
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? formationBlue
                                : formation
                            }
                            alt={formation}
                            data-tooltip-id={`see-match-formation-${item.id}`}
                            onClick={() => handleOpenFormation(item)}
                            onContextMenu={() => handleOpenFormation(item)}
                          />

                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? download_blue
                                : download
                            }
                            alt={statistics}
                            onClick={() => {
                              dispatch(
                                downloadVideosActions.addVideoLink(
                                  item?.videos?.[0]?.link
                                )
                              );
                              addNotification(
                                "Please do not close the tab while the download is in progress.",
                                "info"
                              );
                            }}
                            style={{ cursor: "pointer", pointerEvents: item?.videos?.[0]?.link ? 'auto' : 'none', opacity: item?.videos?.[0]?.link ? 1 : 0.3 }}
                            data-tooltip-id={`download-video-${item.id}`}
                          />
                          <ReactTooltip
                            id={`download-video-${item.id}`}
                            place="bottom"
                            content="Download Full Match"
                          />
                          <ReactTooltip
                            id={`play-full-match-${item.id}`}
                            place="bottom"
                            content="Play Full Match"
                          />
                          <ReactTooltip
                            id={`play-ball-in-play-${item.id}`}
                            place="bottom"
                            content="OBE"
                          />
                          <ReactTooltip
                            id={`see-match-statistics-${item.id}`}
                            place="bottom"
                            content="See Match Statistics"
                          />
                          <ReactTooltip
                            id={`see-match-formation-${item.id}`}
                            place="bottom"
                            content="See Match Formation"
                          />
                        </TeamOptions>
                      )}
                    </TeamStats>
                  );
                })}
            </MatchWrap>
          ) : (
            <div>
              <WrappContent>
                {!showTitle && <h2>Matches</h2>}
              </WrappContent>
              <Empty style={{ height: 105 }} title="No matches found" />
            </div>
          )
        }

        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onClick={() => handleClearFilter()}
        >
          <FormWrap style={{ marginBottom: "20px" }}>
            {availableSeasons && (
              <DropdownWrapper ref={seasonDropdownRef}>
                <div style={{ position: "relative" }} onClick={() => setOpen(!open)}>
                  <TextInput
                    type="text"
                    value={currentSelectedSeason}
                    readOnly
                  />

                  <img
                    src={open ? arrowIconUp : arrowIconDown}
                    alt={arrowIconDown}
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>
                {open && (
                  <OpenedDropdown>
                    {availableSeasons?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleSeasonChoose(item)}
                      >
                        {item.years}
                      </div>
                    ))}
                  </OpenedDropdown>
                )}
              </DropdownWrapper>
            )}

            {competitions && (
              <DropdownWrapper ref={competitionDropdownRef}>
                <div
                  style={{ position: "relative" }}
                  onClick={() => setOpenCompetition(!openCompetition)}
                >
                  <TextInput
                    type="text"
                    value={
                      selectedCompetition?.length == 0
                        ? "Select Competition"
                        : selectedCompetition
                    }
                    readOnly
                  />
                  <img
                    src={openCompetition ? arrowIconUp : arrowIconDown}
                    alt={arrowIconDown}
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>
                {openCompetition && (
                  <OpenedDropdown>
                    {uniqueCompetitions?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleCompetitionChoose(item)}
                      >
                        {item.competition}
                      </div>
                    ))}
                  </OpenedDropdown>
                )}
              </DropdownWrapper>
            )}

            <DropdownWrapper ref={mathesCountDropdownRef}>
              <div
                style={{ position: "relative" }}
                onClick={() => setOpenMatchesCount(!openMatchesCount)}
              >
                <TextInput
                  type="text"
                  value={
                    selectedMatch?.length == 0 ? "PRE-SET" : selectedMatch
                  }
                  readOnly
                />

                <img
                  src={openMatchesCount ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>

              {openMatchesCount && (
                <OpenedDropdown>
                  {matches?.map((item, index) => (
                    <div key={index} onClick={() => handleMatches(item)}>
                      {item.title}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          </FormWrap>

          {loading ? (
            <Button title="fetching data" disabled size={"full"} />
          ) : (
            <Button
              title="search"
              onClick={() => handleSearch()}
              size={"full"}
            />
          )}
        </Filter>

        <Formation
          open={openFormation}
          handleClose={handleCloseFormation}
          match={selectedFormationMatch}
        />
      </div>

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </>
  );
};

export default Matches;

/* eslint-disable max-len */
import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonLanguage } from './ButtonLanguage';
import styled from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import { getTranslates } from '../../store/languages/languagesService';

const ContainerProfile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    background-color: red;
    border: 1px solid red;
    border-radius: 10px;
    min-height: 40px;
    padding: 5px;
    cursor: pointer;

    &.container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 30px;
        right: 20px;
        border: 1px solid red;
        border-radius: 10px;
        width: 250px;
        min-height: 55px;
        padding: 5px;
        cursor: pointer;
        background-color: red;
    }

    &.containerLight {
        background-color: #EBF1FF;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    width: 90%;
    align-items: center;
    gap: 25px;

    & > svg > path {
        fill: red;
    }
`

const Span = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    width: max-content;

    &.spanLight {
        color: black;
    }
`

const LanguageSwitcherDropDownProfile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 190px;
    overflow-y: scroll;

    &.languageSwitcherDropDown {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 450px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
`

const Button = styled.div`
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.eng {
        background-color: transparent;
        color: red;
    }
`

export const LanguageSwitcher = ({ isProfile }) => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation();
    const [openSelector, setOpenSelector] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const languages = useSelector((state) => state.lexicon.languages);
    const isLoading = useSelector((state) => state.lexicon.loagingLanguages)
    // необходима для конкатинации английского языка, так как оне не приходит с back
    const langWithEnglish = [{
        id: 0,
        name: 'English',
        short_name: 'en',
    }, ...languages]
    useEffect(() => {
        const setDefaultLanguageIfExist = () => {
            const language = localStorage.getItem('language');
            language && setSelectedLanguage(language);
        }

        setDefaultLanguageIfExist();
    }, [])
    const changeLanguage = (languageKey) => {
        i18n.changeLanguage(languageKey);
        localStorage.setItem('language', languageKey);
        setSelectedLanguage(languageKey);
        setOpenSelector(false);
        dispatch(getTranslates(languageKey) || 'en');
    };

    const dropdownRef = useRef(null)

    useOutsideClick({
        handleClick: () => setOpenSelector(false),
        ref: dropdownRef,
    })

    const text = langWithEnglish.find((item) => item.short_name === selectedLanguage);
    return (
        !isLoading && (
            <ContainerProfile
                ref={dropdownRef}
                className={`${!isProfile ? 'container' : ''} ${(localStorage.getItem("theme") || 'light') === 'light' ? 'containerLight' : ''}`}
            >
                {
                    !openSelector ? (
                        <ButtonContainer onClick={() => setOpenSelector(true)}>
                            <ButtonContainer>
                                <div>
                                    {selectedLanguage === 'en' ? (
                                        <span
                                            role='img'
                                            className='fflag fflag-US ff-xl ff-round'
                                        />
                                    ) : (
                                        <img width={35} height={35} src={text.flag} alt='flag' />
                                    )}
                                </div>
                                <Button className={'eng'}>
                                    <Span className={`${(localStorage.getItem("theme") || 'light') === 'light' ? 'spanLight' : ''}`}>{text.name}</Span>
                                </Button>
                            </ButtonContainer>
                            <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M12.0981 14.5C10.9434 16.5 8.05662 16.5 6.90192 14.5L1.27276 4.75C0.118059 2.75 1.56143 0.25 3.87084 0.25L15.1292 0.250001C17.4386 0.250002 18.8819 2.75 17.7272 4.75L12.0981 14.5Z' fill='white' />
                            </svg>
                        </ButtonContainer>
                    ) : (
                        <LanguageSwitcherDropDownProfile className={!isProfile ? 'languageSwitcherDropDown' : ''}>
                            {langWithEnglish.map((item) => <ButtonLanguage image={item.flag} key={item.short_name} handleClick={changeLanguage} lang={item.short_name} fullLang={item.name} />)}
                        </LanguageSwitcherDropDownProfile>
                    )
                }
            </ContainerProfile>
        )
    );
};

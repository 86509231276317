import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollableContainer, StyledTable } from "../../style/MatchTable";
import {
  GlobalWrap,
  PlayerTable,
  LeftInfo,
  RightInfo,
  TopHeader,
  StatInfo,
  PlayerPrimaryInfo,
  PlayerPrimaryInfoContent,
  Club,
  TableInfo,
} from "../../style/PlayersStat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import placeholder from "../../images/ic_logo.svg";
import starOn from "../../images/star_on.svg";
import starOff from "../../images/star_off.svg";

import { ReactComponent as SortAscIcon } from "../../images/icons/sortasc_icon.svg";
import { ReactComponent as SortDescIcon } from "../../images/icons/sort_desc.svg";
import { ReactComponent as SortDefaultIcon } from "../../images/icons/sort_default.svg";

import { $apiV1 } from "../../config/api";
import { StickyTableHead } from "./PlayerStat";

const PlayersStat = ({ items, club, tempSelectedFilters, playerSelectedFilters }) => {
  const [favoritePlayers, setFavoritePlayers] = useState({});

  const filteredHomeTeam = items?.home_team?.filter(
    (player) => !player.positions.includes("GK")
  );

  const filteredAwayTeam = items?.away_team?.filter(
    (player) => !player.positions.includes("GK")
  );

  const [tableTypeSortingHome, setTableTypeSortingHome] = useState({ type: 'default', param: '' }); // type default | asc | desc param:number

  const [tableTypeSortingAway, setTableTypeSortingAway] = useState({ type: 'default', param: '' }); // type default | asc | desc param:number

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);

  const scrollRef3 = useRef(null);
  const scrollRef4 = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target === scrollRef1.current) {
        scrollRef2.current.scrollTop = e.target.scrollTop;
      } else if (e.target === scrollRef2.current) {
        scrollRef1.current.scrollTop = e.target.scrollTop;
      } else if (e.target === scrollRef3.current) {
        scrollRef4.current.scrollTop = e.target.scrollTop;
      } else if (e.target === scrollRef4.current) {
        scrollRef3.current.scrollTop = e.target.scrollTop;
      }
    };
    if (scrollRef1.current && scrollRef2.current) {
      scrollRef1.current.addEventListener('scroll', handleScroll);
      scrollRef2.current.addEventListener('scroll', handleScroll);
    }

    if (scrollRef3.current && scrollRef4.current) {
      scrollRef3.current.addEventListener('scroll', handleScroll);
      scrollRef4.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef1.current && scrollRef2.current) {
        scrollRef1.current.removeEventListener('scroll', handleScroll);
        scrollRef2.current.removeEventListener('scroll', handleScroll);
      }
      if (scrollRef3.current && scrollRef4.current) {
        scrollRef3.current.removeEventListener('scroll', handleScroll);
        scrollRef4.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  const navigate = useNavigate();

  const handleSortTableHome = (param) => {
    if (tableTypeSortingHome.type === 'asc' && param.parameter_id === tableTypeSortingHome.param) {
      setTableTypeSortingHome({ type: 'desc', param: param.parameter_id })
    } else if (tableTypeSortingHome.type === 'desc' && param.parameter_id === tableTypeSortingHome.param) {
      setTableTypeSortingHome({ type: 'default', param: param.parameter_id })
    } else {
      setTableTypeSortingHome({ type: 'asc', param: param.parameter_id })
    }
  }

  const handleSortTableAway = (param) => {
    if (tableTypeSortingAway.type === 'asc' && param.parameter_id === tableTypeSortingAway.param) {
      setTableTypeSortingAway({ type: 'desc', param: param.parameter_id })
    } else if (tableTypeSortingAway.type === 'desc' && param.parameter_id === tableTypeSortingAway.param) {
      setTableTypeSortingAway({ type: 'default', param: param.parameter_id })
    } else {
      setTableTypeSortingAway({ type: 'asc', param: param.parameter_id })
    }
  }

  const sortTableHome = (a, b) => {
    if (tableTypeSortingHome.param) {
      const paramA = a.stats?.find(param => param.parameter_id === tableTypeSortingHome.param);
      const paramB = b.stats?.find(param => param.parameter_id === tableTypeSortingHome.param);
      if (paramA && paramB) {
        if (tableTypeSortingHome.type === 'asc') {
          return paramA.value - paramB.value;
        }
        if (tableTypeSortingHome.type === 'desc') {
          return paramB.value - paramA.value;
        }
      }
      if (paramA) return -1;
      if (paramB) return 1;
    }
    return 0;
  }

  const sortTableAway = (a, b) => {
    if (tableTypeSortingAway.param) {
      const paramA = a.stats?.find(param => param.parameter_id === tableTypeSortingAway.param);
      const paramB = b.stats?.find(param => param.parameter_id === tableTypeSortingAway.param);
      if (paramA && paramB) {
        if (tableTypeSortingAway.type === 'asc') {
          return paramA.value - paramB.value;
        }
        if (tableTypeSortingAway.type === 'desc') {
          return paramB.value - paramA.value;
        }
      }
      if (paramA) return -1;
      if (paramB) return 1;
    }
    return 0;
  }



  return (
    <GlobalWrap>
      <PlayerTable>
        <Club>
          <h2>{club?.home_team}</h2>
        </Club>
        <TableInfo>
          <LeftInfo>
            <TopHeader>
              <h3>#</h3>
              <h3>Player</h3>
              <h3 className="position">Pos.</h3>
            </TopHeader>
            <StatInfo ref={scrollRef1} style={{ height: '62vh', overflow: 'hidden' }}>
              <PlayerPrimaryInfo>
                {items?.home_team?.sort(sortTableHome)?.map((item, index) => {
                  if (item.positions.includes("GK")) {
                    return null;
                  }
                  const isFavorite = favoritePlayers[item.id] ?? false;
                  return (
                    <PlayerPrimaryInfoContent
                      key={item.id}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={isFavorite ? starOn : starOff}
                        alt="Favorite star"
                        style={{
                          width: "18px",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleFavorite(item)}
                      />
                      <img
                        src={
                          item.photo
                            ? `https://platform.smrtstats.com:8888/${item.photo}`
                            : placeholder
                        }
                      />
                      <h3>{item.number}</h3>

                      <h3
                        onClick={() =>
                          navigate(`/tournaments/player/${item.id}`)
                        }
                        data-tooltip-id={`full-name-${item.name}-${item.surname}`}
                      >
                        {item.name} {item.surname}
                      </h3>

                      <ReactTooltip
                        id={`full-name-${item.name}-${item.surname}`}
                        place="bottom"
                        content={`${item.name} ${item.surname}`}
                      />
                      <h3 className="position">{item.positions[0]}</h3>
                    </PlayerPrimaryInfoContent>
                  );
                })}
              </PlayerPrimaryInfo>
            </StatInfo>
          </LeftInfo>
          <RightInfo>
            <ScrollableContainer ref={scrollRef2} style={{ height: '65vh' }}>
              <StyledTable>
                <StickyTableHead>
                  <tr>
                    {playerSelectedFilters?.checkedIds?.length === 0
                      ? null
                      : filteredHomeTeam
                        ?.find((item) => item.stats?.length > 0)
                        ?.stats?.filter(
                          (stat) =>
                            playerSelectedFilters?.checkedIds?.length === 0 ||
                            playerSelectedFilters?.checkedIds?.includes(
                              stat.parameter_id
                            )
                        )
                        ?.sort((a, b) => a?.parameter_id - b?.parameter_id)
                        ?.map((stat, index) => {
                          // Create a shorthand based on the first letters of the parameter name
                          const shorthand = stat.parameter_name
                            .split(" ")
                            .filter((word) => word.length > 0) // Filter out any empty strings
                            .map((word) => word[0].toUpperCase())
                            .join("");

                          return (
                            <th
                              key={stat.parameter_id}
                              data-tooltip-id={`name-${stat.parameter_id}`}
                              onClick={() => handleSortTableHome(stat)}
                            >
                              {shorthand}
                              {
                                tableTypeSortingHome.type === 'asc' && tableTypeSortingHome.param === stat.parameter_id ? (
                                  <SortAscIcon />
                                ) : tableTypeSortingHome.type === 'desc' && tableTypeSortingHome.param === stat.parameter_id ? (
                                  <SortDescIcon />
                                ) : (
                                  <SortDefaultIcon />
                                )
                              }
                              <ReactTooltip
                                id={`name-${stat.parameter_id}`}
                                place="bottom"
                                content={stat.parameter_name}
                              />
                            </th>
                          );
                        })}
                  </tr>
                </StickyTableHead>

                <tbody className="playerStatsTable">
                  {playerSelectedFilters?.checkedIds?.length === 0
                    ? null
                    : filteredHomeTeam?.sort?.(sortTableHome)?.map((player, index) => (
                      <tr key={player.id}>
                        {player?.stats
                          ?.filter(
                            (stat) =>
                              playerSelectedFilters?.checkedIds?.length ===
                              0 ||
                              playerSelectedFilters?.checkedIds?.includes(
                                stat.parameter_id
                              )
                          )
                          ?.sort((a, b) => a?.parameter_id - b?.parameter_id)
                          .map((stat) => (
                            <td
                              key={stat.parameter_id}
                              className="tableNewStyle"
                              style={{
                                // color: "#fff",
                                cursor: stat.markers ? "pointer" : "",
                              }}
                              onClick={() => {
                                if (stat.markers) {
                                  navigate(
                                    `/tournaments/match/video?title=${stat.parameter_name
                                    } - ${player?.name} ${player?.surname
                                    }&markers=${stat.markers.join(",")}`
                                  );
                                }
                              }}
                            >
                              {Number.isInteger(stat.value)
                                ? stat.value.toLocaleString()
                                : typeof stat.value === "number"
                                  ? stat.value.toFixed(1)
                                  : stat.value}
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            </ScrollableContainer>
          </RightInfo>
        </TableInfo>
      </PlayerTable>
      <PlayerTable>
        <Club style={{ marginTop: "20px" }}>
          <h2>{club?.away_team}</h2>
        </Club>
        <TableInfo>
          <LeftInfo>
            <TopHeader>
              <h3>#</h3>
              <h3>Player</h3>
              <h3 className="position">Pos.</h3>
            </TopHeader>
            <StatInfo ref={scrollRef3} style={{ height: '62vh', overflow: 'hidden' }}>
              <PlayerPrimaryInfo>
                {items?.away_team?.sort(sortTableAway)?.map((item, index) => {
                  if (item.positions.includes("GK")) {
                    return null;
                  }
                  const isFavorite = favoritePlayers[item.id] ?? false;

                  return (
                    <PlayerPrimaryInfoContent key={item.id}>
                      <img
                        src={isFavorite ? starOn : starOff}
                        alt="Favorite star"
                        style={{
                          width: "18px",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleFavorite(item)}
                      />
                      <img
                        src={
                          item.photo
                            ? `https://platform.smrtstats.com:8888/${item.photo}`
                            : placeholder
                        }
                      />
                      <h3>{item.number}</h3>

                      <h3
                        onClick={() =>
                          navigate(`/tournaments/player/${item.id}`)
                        }
                        data-tooltip-id={`full-name-${item.name}-${item.surname}`}
                      >
                        {item.name} {item.surname}
                      </h3>

                      <ReactTooltip
                        id={`full-name-${item.name}-${item.surname}`}
                        place="bottom"
                        content={`${item.name} ${item.surname}`}
                      />
                      <h3 className="position">{item.positions[0]}</h3>
                    </PlayerPrimaryInfoContent>
                  );
                })}
              </PlayerPrimaryInfo>
            </StatInfo>
          </LeftInfo>
          <RightInfo>
            <ScrollableContainer ref={scrollRef4} style={{ height: '65vh' }}>
              <StyledTable>
                <StickyTableHead>
                  <tr>
                    {playerSelectedFilters?.checkedIds?.length === 0
                      ? null
                      : filteredHomeTeam
                        ?.find((item) => item.stats?.length > 0)
                        ?.stats?.filter(
                          (stat) =>
                            playerSelectedFilters?.checkedIds?.length === 0 ||
                            playerSelectedFilters?.checkedIds?.includes(
                              stat.parameter_id
                            )
                        )
                        ?.sort((a, b) => a?.parameter_id - b?.parameter_id)
                        ?.map((stat, index) => {
                          // Create a shorthand based on the first letters of the parameter name
                          const shorthand = stat.parameter_name
                            .split(" ")
                            .filter((word) => word.length > 0) // Filter out any empty strings
                            .map((word) => word[0].toUpperCase())
                            .join("");

                          return (
                            <th
                              key={stat.parameter_id}
                              data-tooltip-id={`name-${stat.parameter_id}`}
                              onClick={() => handleSortTableAway(stat)}
                            >
                              {shorthand}
                              {
                                tableTypeSortingAway.type === 'asc' && tableTypeSortingAway.param === stat.parameter_id ? (
                                  <SortAscIcon />
                                ) : tableTypeSortingAway.type === 'desc' && tableTypeSortingAway.param === stat.parameter_id ? (
                                  <SortDescIcon />
                                ) : (
                                  <SortDefaultIcon />
                                )
                              }
                              <ReactTooltip
                                id={`name-${stat.parameter_id}`}
                                place="bottom"
                                content={stat.parameter_name}
                              />
                            </th>
                          );
                        })}
                  </tr>
                </StickyTableHead>

                <tbody className="playerStatsTable">
                  {playerSelectedFilters?.checkedIds?.length === 0
                    ? null
                    : filteredAwayTeam?.sort(sortTableAway)?.map((player, index) => (
                      <tr key={index}>
                        {player?.stats
                          ?.filter(
                            (stat) =>
                              playerSelectedFilters?.checkedIds?.length ===
                              0 ||
                              playerSelectedFilters?.checkedIds?.includes(
                                stat.parameter_id
                              )
                          )
                          ?.sort((a, b) => a?.parameter_id - b?.parameter_id)
                          .map((stat) => (
                            <td
                              key={stat.parameter_id}
                              className="tableNewStyle"
                              style={{
                                // color: "#fff",
                                cursor: stat.markers ? "pointer" : "",
                              }}
                              onClick={() =>
                                navigate(
                                  `/tournaments/match/video?title=${stat.parameter_name
                                  } - ${player?.name} ${player?.surname
                                  }&markers=${stat.markers?.join(",")}`
                                )
                              }
                            >
                              {Number.isInteger(stat.value)
                                ? stat.value.toLocaleString()
                                : typeof stat.value === "number"
                                  ? stat.value.toFixed(1)
                                  : stat.value}
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            </ScrollableContainer>
          </RightInfo>
        </TableInfo>
      </PlayerTable>
    </GlobalWrap>
  );
};

export default PlayersStat;

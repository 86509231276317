import React, { useRef, useState } from 'react'
import { ActionButtons, DownloadTypeSelection, VideoActions, VideoActionsContent, VideoActionsContentTop, VideoLists } from '../../../../style/VideoStyle';
import Button from '../../../../components/button';
import { convertIsoToYyyyMmDd } from '../helpers';

import { ReactComponent as PlayVideo } from "../../../../images/ic_play-button.svg";
import closeIcon from "../../../../images/ic_close.svg";

const CheckBoxPanel = ({ item, toggleAllEventsOnMatch, maxWidthTitle }) => {
    const [state, setState] = useState(false)

    const handleChangeCheckCut = (checked, items) => {
        toggleAllEventsOnMatch(checked, items.markers)
        setState(!state);
    };
    return (
        <VideoLists
            key={item?.id}
            className='video-braker'
            title={`${convertIsoToYyyyMmDd(item.match?.date)} ${item.match.home_team.name} ${item.match?.score} ${item.match.away_team.name}`}
        >
            <>
                <div className="videoList">

                    <h3 style={{ maxWidth: maxWidthTitle }}>
                        {" "}
                        {`
                            ${convertIsoToYyyyMmDd(item.match?.date)}
                            ${item.match.home_team.name}
                            ${item.match?.score}
                            ${item.match.away_team.name}
                         `}
                    </h3>
                </div>
                <input
                    type={"checkbox"}
                    checked={state}
                    onChange={(e) => handleChangeCheckCut(e.target.checked, item)}
                />
            </>
        </VideoLists>
    )
}


export const EventsPanel = (props) => {
    const {
        countEpisodes,
        countMathes,
        videoCuts,
        handlePlayVideo,
        toggleAllEventsOnMatch,
        toggleCheckCut,
        selectedEventsCount,
        onOpenAddPlaylistModal,
        currentVideoIndex,
        loadingDownloadCuts,
        handleDownloadSelectedCuts,
        handleDownloadSelectedCutsInOneClip,
        loadingMarkers,
    } = props;

    const parent = useRef(null);

    const [openDownloadTypeSelection, setOpenDownloadTypeSelection] = useState(false);

    const handlePressDownloadOneClip = () => {
        handleDownloadSelectedCutsInOneClip?.();
        setOpenDownloadTypeSelection(false);
    }

    const handlePressDownloadZip = () => {
        handleDownloadSelectedCuts?.();
        setOpenDownloadTypeSelection(false);
    }

    return (
        <VideoActions ref={parent}>
            <VideoActionsContent>
                <h3 className='video-actions-title'>
                    {loadingMarkers ? 'Loading events...' : (
                        <>
                            <span>{countEpisodes}</span> events • <span>{countMathes}</span> matches
                        </>
                    )}
                </h3>
                <VideoActionsContentTop>
                    {videoCuts?.map((item, index) => {
                        if (item?.braker) {
                            return <CheckBoxPanel maxWidthTitle={parent.current?.offsetWidth - 66 || 255} item={item} toggleAllEventsOnMatch={toggleAllEventsOnMatch} />
                        }
                        return (
                            <VideoLists
                                key={index}
                                className={currentVideoIndex === index ? 'active' : ''}
                                title={`${!item?.video ? 'No video' : item?.creator ? `${item.creator.display_name}` : ''
                                    }${item.creator_team?.name ? ` - ${item.creator_team?.name} ` : ''}, ${item.action
                                    }`}
                                onClick={() => handlePlayVideo(item?.video, +(item?.second || 0), item, index)}
                            >
                                <>
                                    <div
                                        className="videoList"
                                        style={{ opacity: item?.video ? 1 : 0.5, pointerEvents: item?.video ? 'auto' : 'none' }}
                                    >
                                        <PlayVideo />
                                        <h3
                                            style={{ maxWidth: parent.current?.offsetWidth - 66 || 255 }}
                                        >
                                            {" "}
                                            {item?.title}
                                        </h3>
                                    </div>
                                    <input
                                        type={"checkbox"}
                                        checked={item.checked}
                                        disabled={!item.video}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => toggleCheckCut(e.target.checked, item)}
                                    />
                                </>
                            </VideoLists>
                        );
                    })}
                </VideoActionsContentTop>
                <ActionButtons>
                    <Button
                        disabled={selectedEventsCount === 0}
                        title={selectedEventsCount ? `Add ${selectedEventsCount} event${selectedEventsCount === 1 ? '' : 's'} to playlist` : 'add to playlist'}
                        onClick={() => onOpenAddPlaylistModal(true)}
                    />
                    <Button
                        disabled={videoCuts?.length === 0 || loadingDownloadCuts}
                        title={loadingDownloadCuts ? 'Loading...' : selectedEventsCount ? `download ${selectedEventsCount} event${selectedEventsCount === 1 ? '' : 's'}` : `download all`}
                        color={"secondary"}
                        onClick={() => setOpenDownloadTypeSelection(true)}
                    />
                    {openDownloadTypeSelection && (
                        <DownloadTypeSelection>
                            <Button
                                disabled={loadingDownloadCuts}
                                title="One clip"
                                color={"secondary"}
                                onClick={handlePressDownloadOneClip}
                            />
                            <Button
                                disabled={loadingDownloadCuts}
                                title="Separate clips"
                                color={"secondary"}
                                onClick={handlePressDownloadZip}
                            />
                            <img src={closeIcon} alt={closeIcon} onClick={() => setOpenDownloadTypeSelection(false)} />
                        </DownloadTypeSelection>
                    )}
                </ActionButtons>
            </VideoActionsContent>
        </VideoActions>
    )
}

import styled from "styled-components";
import { breakpoints } from "./size";

export const IntroTopRight = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const IntroTopSearch = styled.div`
  display: flex;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;

export const SearchWrap = styled.div`
  position: relative;
  border-radius: 1px;
  border: 1px solid rgba(44, 50, 48, 1);
  opacity: 1;
  margin-right: 10px;
  padding-left: 10px;

  input {
    background: transparent;
    border: none;
    height: 30px;
    outline: none;
    width: 221px;
    color: rgba(207, 218, 230, 1);
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    &:placeholder {
      color: #2c3230;
    }
  }
`;

export const CompanreButton = styled.button`
  border-radius: 1px;
  opacity: 1;
  background-color: ${(props) => props.theme.green};
  border: none;
  padding: 13px 10px;
  opacity: 1;
  color: ${(props) => props.theme.black};
  font-family: "SpaceGrotesk";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const TopTableNames = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  position: relative;
  width: 100%;

  button {
    position: absolute;
    top: -20px;
    right: 0;
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    width: 92px;
    height: 40px;
    cursor: pointer;
  }
`;

export const TableNameWrap = styled.div`
  &.tableNameWrapTeamPlayer {
    display: flex;

    .react-tooltip {
      background: #cfdae6;
      opacity: 1;
      color: rgba(4, 8, 2, 1);
      font-family: "SatoshiBold";
      font-size: 16px;
      font-weight: 700;
      font-style: Bold;
      letter-spacing: 0px;
      text-align: center;
      line-height: 24px;
    }

    &:nth-child(n+3) {
      cursor: pointer;
    }

    &:nth-child(1) {
      padding-left: 44px;
      width: calc(32% - 17px);
    }

    &:nth-child(2) {
      width: 19.3%;
    }

    &:nth-child(6) {
      width: calc(5% + 5px);
    }

    @media only screen and (max-width: 1700px) {
      &.tableNameWrapTeamPlayer {
        &:nth-child(2) {
          width: 19%;
        }
        &:nth-child(5) {
          width: calc(5% + 11px);
        }
        &:nth-child(6) {
          width: calc(5%);
        }
      }
    }

    @media only screen and (max-width: 1500px) {
      &.tableNameWrapTeamPlayer {
         &:nth-child(2) {
          width: 18.3%;
        }
        &:nth-child(4) {
          width: calc(5% + 4px);
        }
      }
    } 
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
    white-space: nowrap;
  }

  &:nth-child(1) {
    padding-left: 44px;
    width: calc(24% - 17px);
  }
  &:nth-child(2) {
    width: calc(19% + 5px);
  }
  &:nth-child(3) {
    width: calc(5% + 6px);

    h5 {
      text-align: center;
    }
  }
  &:nth-child(4) {
    width: calc(5% + 7px);
  }
  &:nth-child(5) {
    width: calc(5% + 17px);
  }
  &:nth-child(6) {
    width: calc(5% - 1px);

    h5 {
      text-align: center;
    }
  }
  &:nth-child(7) {
    width: calc(5% + 8px);
  }
  &:nth-child(8) {
    width: 5%;
  }
  &:nth-child(9) {
    width: 5%;
  }
`;

export const TableNameWrapSmrtSearch = styled.div`
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    z-index: 9;
  }

  &:nth-child(1) {
    width: calc(9% - 8px);
    margin-right: 5%;
  }
  &:nth-child(2) {
    width: calc(5% + 8px);
  }
  &:nth-child(3) {
    width: calc(14% - 6px);
    margin-right: 3%;
  }
  &:nth-child(n+4) {
    width: 3.13%;
    margin-right: 12px;
    white-space: nowrap;
  }

  .tableGk&&:nth-child(n+4) {
    width: 4.9%;
  }

  .tableGk&:nth-child(1) {
    width: calc(21% - 8px);
    margin-right: 2%;
  }

  .tableGk&:nth-child(2) {
    width: calc(4% + 8px);
  }

  .tableGk&:nth-child(3) {
    width: calc(25% - 6px);
    margin-right: 3%;
  }

  @media only screen and (max-width: 1800px) {
    &:nth-child(n+4) {
      width: 3.05%;
    }
  }

  @media only screen and (max-width: 1650px) {
    &:nth-child(n+4) {
      width: 3%;
    }
  }
  @media only screen and (max-width: 1550px) {
    &:nth-child(n+4) {
      width: 2.9%;
    }
  }
  @media only screen and (max-width: 1450px) {
    &:nth-child(n+4) {
      width: 2.8%;
    }
    .tableGk&:nth-child(n+4) {
      width: 4.8%;
    }
  }
  @media only screen and (max-width: 1350px) {
    &:nth-child(n+4) {
      width: 2.7%;
    }
  }
`;
export const TableWrap = styled.ul`
  border: 1px solid ${(props) => props.theme.lines};
  margin-bottom: 30px;
  max-height: 60vh;
  overflow: auto;
  scrollbar-width: auto;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 0px;
  }
`;

export const TableContent = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;

  &.tableLabelWrapTeamPlayer {
    h3 {
      text-align: center;
    }

    div:nth-child(3) {
      width: 6%;
    }
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  @media only screen and (${breakpoints.mobile}) {
    padding-left: 10px;
  }

  &:nth-child(odd) {
    background: ${(props) => props.theme.row};
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }
`;

export const ProfileImage = styled.div`
  position: relative;
  display: flex;

  @media only screen and (${breakpoints.mobile}) {
    width: 20px;
    height: 20px;
  }

  .flag {
    position: absolute;
    object-fit: cover;
    top: -5px;
    right: -5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  span {
    position: absolute;
    position: absolute;
    top: -9px;
    right: -6px;

    @media only screen and (${breakpoints.mobile}) {
      top: -12px;
      img {
        width: 10px;
      }
    }
  }
`;

export const Player = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 21%;

  &.teamPlayerTab {
    width: 33%;

    @media only screen and (${breakpoints.mobile}) {
      width: 60%;
    }

    h3 {
      max-width: 250px;
    }
  }

  h3 {
    max-width: 60px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (${breakpoints.mobile}) {
      max-width: 120px;
    }
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    z-index: 9;
  }

  @media only screen and (${breakpoints.mobile}) {
    width: 55%;
  }
`;

export const Position = styled.div`
  width: 20%;
  line-height: 0;

  &.smrtSearchPosition {
    width: 16%;
  }

  h3 {
    max-width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.playerTeamPage {
    h3 {
      max-width: 180px;
    }
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;

    ul {
      list-style-type: none;
      li {
        color: rgba(4, 8, 2, 1);
        font-family: "SatoshiBold";
      }
    }
  }
`;

export const Club = styled.div`
  width: 16%;

  h3 {
    max-width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const Passes = styled.div`
  width: 6%;

  h3 {
    text-align: center;
  }
`;

export const RightContent = styled.div`
  width: 17%;
  display: flex;
  margin-left: auto;

  @media only screen and (${breakpoints.mobile}) {
    width: 40%;
  }
`;

export const SeeMoreWrap = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiBold";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: right;
  line-height: 24px;
  padding: 20px;
  text-transform: capitalize;
  cursor: pointer;

  @media only screen and (${breakpoints.mobile}) {
    padding: 17px 10px;
  }

  @media only screen and (${breakpoints.mobile}) {
    width: 95px;
  }
`;

export const Compare = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 20px;

  .react-tooltip {
      background: #cfdae6;
      opacity: 1;
      color: rgba(4, 8, 2, 1);
      font-family: "SatoshiBold";
      font-size: 16px;
      font-weight: 700;
      font-style: Bold;
      letter-spacing: 0px;
      text-align: center;
      line-height: 24px;
  }
`;

export const ImagesWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterButton = styled.div`
  width: 44px;
  height: 44px;
  opacity: 1;
  background-color: ${(props) => props.theme.green};
  border-radius: 50%;
  position: absolute;
  bottom: 40px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BetaVersion = styled.div`
  position: absolute;
  right: 0;
  bottom: 120px;
  background: ${(props) => props.theme.green};
  padding: 10px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 999;

  img {
    width: 30px;
    height: 30px;
  }

  span {
    position: relative;
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    color: ${(props) => props.theme.black};
    width: 300px;

    img {
      position: absolute;
      right: 2px;
      top: 2px;
      width: 15px;
      height: 15px;
    }
  }
`;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { breakpoints } from "../../../style/size";
import { $apiV1 } from "../../../config/api";
import Button from "../../../components/button";
import debounce from "lodash.debounce";

// Style
import {
  BottomFields,
  EventMatchFilter,
  EventsMatchesWrap,
  FieldCount,
  FootballField,
  FootballFieldCount,
  LeftTopField,
  ListOfMatches,
  MiddleTopField,
  RightTopField,
  TopFields,
} from "../../../style/EventsMatchesStyle";

// Images
import backgroundImage from "../../../images/ic_filedhalf.png";
import goalFieldBackground from "../../../images/ic_goal.png";
import goal from "../../../images/ic_golman.svg";
import missedShot from "../../../images/ic_x.svg";
import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";

import {
  DirectionStatus,
  EventCard,
  EventCardWrapper,
  EventContent,
  FootballFielOptions,
  ParametersTabWrapper,
  VideoSettingsWrapper,
} from "./EventsMatches";
import {
  DropdownWrapper,
  OpenedDropdown,
} from "../../../components/table/MatchTable";
import TextInput from "../../../components/inputs/TextInput";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../../style/Favorites";
import { TimelineChange } from "../../../components/Events/TimelineChange";
import { LoadingState } from "../../../style/Overview";
import { Oval } from "react-loader-spinner";
import { OwnOpponnentSwitcherWrapper } from "./ShotsTeam";
import { findMaxSecondMarker, getUniqueArrayObjectsByKey, mirrorCoordinates } from "../../../helpers";
import useOutsideClick from "../../../hooks/useOutsideClick";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '0.6' : '1'};
`;

export const DropdownButton = styled.div`
     background: transparent;
  border: none;
  border-bottom: 1px solid
    ${(props) =>
    props.error
      ? (props) => props.theme.red
      : (props) => props.theme.blueish2};
  outline: none;
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  padding-left: 5px;
  padding-bottom: 4px;

  &:placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }

    &:disabled {
      color: ${(props) => props.theme.blueish2}; !important;
    }

    img{
      position: absolute;
      right: 0;
      bottom: 5px;
      cursor: pointer;
    }
`;

export const DropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  z-index: 999;
  width: 100%;

  border-radius: 2px;
  opacity: 1;
  background: ${(props) => props.theme.black};
  max-height: 200px;
  overflow: scroll;
  border: 1px solid ${(props) => props.theme.borderColor};

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.black};
    }
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? (props) => props.theme.green
      : (props) => props.theme.lines};
  color: ${(props) =>
    props.selected
      ? (props) => props.theme.black
      : (props) => props.theme.white} !important;

  &:hover {
    background-color: #ddd;
    color: ${(props) => props.theme.white} !important;
  }
`;

const InfoBox = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.blueish};
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  display: ${(props) => (props.visible ? "block" : "none")};
  top: ${(props) => props.top}px;
  // left: ${(props) => props.left}px;
  // transform: translate(-50%, -100%);
  z-index: 999;
  text-align: center;

  img {
    width: 65px;
    height: 50px;
    object-fit: contain;
    opacity: 1;
  }

  h4 {
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  h5 {
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    text-decoration: underline;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    line-height: 24px;
  }
`;

const CrossesTeam = ({ players, paramsShotList }) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [choosenSeasson, setChoosenSeasson] = useState("");
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [matchesList, setMatchesList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [opponentsMarkers, setOpponentsMarkers] = useState([]);
  const [response, setResponse] = useState(null);
  const [responseOpponents, setReponseOpponents] = useState(null);
  const [hoveredEvent, setHoveredEvent] = useState(null);

  const [open, setOpen] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState("");

  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [timeLineRange, setTimeLineRange] = useState({ min: 0, max: 90 });

  const [displayedMathes, setDisplayedMathes] = useState(
    () => players?.matches_list || []
  );
  const [lastMatches, SetLastMatches] = useState(
    displayedMathes?.length || 0
  );
  const [selectedMatch, setSelectedMatch] = useState("");
  const [openMatchesCount, setOpenMatchesCount] = useState(false);

  const [showDirection, setShowDirection] = useState(false);

  const [typeShots, setTypeShots] = useState('own'); // own | opponent

  const dataForTimeLineChange = useRef(null);

  const currentDisplayedReponseData = useMemo(() => {
    if (typeShots === 'own') {
      return response
    } else {
      return responseOpponents;
    }
  }, [response, responseOpponents, typeShots]);

  useEffect(() => {
    dataForTimeLineChange.current = currentDisplayedReponseData;
    setDisplayedEvents(currentDisplayedReponseData?.events ?? []);
  }, [currentDisplayedReponseData])

  const matches = useMemo(
    () => [
      {
        id: 5,
        value: 0,
        title: "Deselect all matches",
      },
      {
        id: 1,
        value: displayedMathes?.length || 0,
        title: "All matches",
      },
      {
        id: 2,
        value: 3,
        title: "Last 3 matches",
      },
      {
        id: 3,
        value: 5,
        title: "Last 5 matches",
      },
      {
        id: 4,
        value: 10,
        title: "Last 10 matches",
      },
    ],
    [players]
  );

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(prev => prev.includes(item.competition__id) ? prev.filter(oldItem => oldItem !== item.competition__id) : [...prev, item.competition__id]);
    setSelectedCompetition(choosenCompetition?.length > 0 ? `${item.title} + ${choosenCompetition?.length}` : item.title);
  };

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSelectionChange = (ids) => {
    setMatchesList((prev) =>
      Array.isArray(ids)
        ? [...new Set([...prev, ...ids])]
        : prev.includes(ids)
          ? prev.filter((item) => item !== ids)
          : [...prev, ids]
    );
  };

  const handleSeasons = async () => {
    try {
      setChoosenCompetition("");
      setMatchesList([]);
      setLoadingSeason(true);
      const response = await $apiV1.get(
        `/statistics/team/${id}?year_season_id=${choosenSeasson}`
      );

      setDisplayedMathes(response.data?.matches_list);
      setCompetitions(response.data.matches_played);
      SetLastMatches(response.data?.matches_list?.length || 0);

      // const filteredMatches = response.data?.matches_list
      //   ?.filter((match) => match.competition_id === choosenCompetition)
      //   ?.map((match) => match.id);

      // setMatchesList(filteredMatches || []);

      // Filter matches to show based on the updated list
      const uniqueArrayComp = getUniqueArrayObjectsByKey(
        response.data.matches_played,
        "competition__id"
      );
      const years = response?.data?.available_seasons?.find(item => item.id === +choosenSeasson)
      setChoosenSeasson(choosenSeasson || response?.data?.available_seasons?.[0].id || '');
      setSelectedSeason(years?.years || response?.data?.available_seasons?.[0].years || '');
      setChoosenCompetition(uniqueArrayComp?.map(item => item.competition__id));
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoadingSeason(false);
    }
  };

  useEffect(() => {
    handleSeasons();
  }, [choosenSeasson]);

  useEffect(() => {
    if (choosenCompetition) {
      const filteredMatches = displayedMathes
        ?.filter((match) => choosenCompetition.includes(+match.competition_id))
        ?.slice(0, lastMatches)
        ?.map((match) => match.id);
      setMatchesList(filteredMatches);
    } else {
      const filteredMatches = displayedMathes
        ?.slice(0, lastMatches)
        ?.map((match) => match.id);
      setMatchesList(filteredMatches);
    }
  }, [choosenCompetition, lastMatches, displayedMathes]);

  useEffect(() => {
    if (choosenCompetition) {
      const filteredMatches = players?.matches_list
        ?.filter((match) => match.competition_id === choosenCompetition)
        ?.map((match) => match.id);
      handleSelectionChange(filteredMatches);
    }
  }, [choosenCompetition]);

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const fetchTeamStats = async (needOpponentMarkers = false, setMarkersFunc) => {
    try {
      const response = await $apiV1.get(`/stats/team/${id}`, {
        params: {
          matches: matchesList.join(","),
          params: checkedIds.join(","),
          oponnent: needOpponentMarkers,
        },
      });

      const allMarkers = response.data.stats.flatMap((stat) =>
        stat.parameters.flatMap((param) => param.markers)
      );

      setMarkersFunc?.(allMarkers);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (matchesList.length > 0 && checkedIds.length > 0) {
      fetchTeamStats(false, setMarkers);
      fetchTeamStats(true, setOpponentsMarkers);
    }

    if (checkedIds.length === 0) {
      setDisplayedEvents([]);
      setResponse(null);
      setReponseOpponents(null);
      setMarkers([]);
      setOpponentsMarkers([]);
    }
  }, [matchesList, checkedIds]);

  const [loadingMarkers, setLoadingMarkers] = useState(false);

  const fetchDataMarkers = async (markers, setFunc) => {
    setLoadingMarkers(true);
    try {
      const result = await $apiV1.post("/stats/events_search/", {
        markers,
      });
      setFunc?.(result.data);
      const max = findMaxSecondMarker(result.data.events);
      setTimeLineRange({ min: 0, max: Math.ceil(+max / 60 / 5) * 5 });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingMarkers(false);
    }
  };

  useEffect(() => {
    if (markers.length > 0) {
      fetchDataMarkers(markers, setResponse);
    }
  }, [markers]);

  useEffect(() => {
    if (opponentsMarkers.length > 0) {
      fetchDataMarkers(opponentsMarkers, setReponseOpponents);
    }
  }, [opponentsMarkers]);

  const fieldWidth = 649;
  const fieldHeight = 482;
  const topFieldHeight = fieldHeight / 2; // Height of the top field

  const sectionWidth = fieldWidth / 3; // Divide the top field into 3 sections

  // const bottomFieldHeight = fieldHeight / 2;

  // // Extract all x and y values from the response
  // const xValues =
  //   currentDisplayedReponseData?.events
  //     .map((marker) => marker.coord_x ?? 0) // Replace null or undefined with 0
  //     .filter((val) => val !== undefined) || [];

  // const yValues =
  //   currentDisplayedReponseData?.events
  //     .map((marker) => marker.coord_y ?? 0) // Replace null or undefined with 0
  //     .filter((val) => val !== undefined) || [];

  // Find the minimum and maximum values for x and y
  // const xMin = xValues.length > 0 ? Math.min(...xValues) : 0;
  // const xMax = xValues.length > 0 ? Math.max(...xValues) : fieldWidth;
  // const yMin = yValues.length > 0 ? Math.min(...yValues) : 0;
  // const yMax = yValues.length > 0 ? Math.max(...yValues) : fieldHeight;

  const convertCoordinates = (x, y) => {
    const scaleX = fieldWidth / 100;
    const scaleY = fieldHeight / 100;
    return {
      x: x * scaleX,
      y: fieldHeight - y * scaleY,
    };
  };
  const fieldPadding = 0;

  //Had to be replaced because the dots didn't match the video
  function convertCoordinatesWithPadding(coord_x, coord_y) {
    const scaleX = fieldHeight / 52.5; // 52.5 is half 105 footbal field dimensions
    const scaleY = fieldWidth / 68;

    const newCoordX = Math.min(Math.max(coord_y * scaleX, 0), fieldWidth);
    const newCoordY = Math.min(Math.max(coord_x * scaleY - 30, 0), fieldHeight);

    return { x: Math.round(newCoordX), y: Math.round(newCoordY) };
  }

  // const convertCoordinatesWithPadding = (x, y) => {
  //   const xRange = xMax - xMin;
  //   const yRange = yMax - yMin;
  //   const fieldPadding = 0; // Adjust padding if needed

  //   const scaleX = xRange !== 0 ? (fieldWidth - 2 * fieldPadding) / xRange : 1;
  //   const scaleY = yRange !== 0 ? (fieldHeight - 2 * fieldPadding) / yRange : 1;

  //   const convertedX = fieldPadding + (x - xMin) * scaleX;
  //   const convertedY = fieldHeight - fieldPadding - (y - yMin) * scaleY;

  //   const clampedX = Math.max(0, Math.min(fieldWidth, convertedX));
  //   const clampedY = Math.max(0, Math.min(fieldHeight, convertedY));

  //   return {
  //     x: clampedX,
  //     y: clampedY,
  //   };
  // };

  // const imageWidthPx = 649;
  // const imageHeightPx = 482;
  // const goalWidthMeters = 7.32;
  // const goalHeightMeters = 2.44;
  // const paddingPx = 100;

  // const goalWidthPx = imageWidthPx - 2 * paddingPx;
  // const goalHeightPx = imageHeightPx - 2 * paddingPx;

  // const scaleX = goalWidthPx / goalWidthMeters;
  // const scaleY = goalHeightPx / goalHeightMeters;

  // const goalCenterX = imageWidthPx / 2;
  // const goalCenterY = imageHeightPx / 2;

  const uniqueCompetitions = useMemo(() => {
    if (competitions?.length > 0) {
      const uniqueArray = getUniqueArrayObjectsByKey(
        competitions,
        "competition__id"
      );
      return uniqueArray;
    }
    return [];
  }, [competitions]);

  const isInTopField = (x, y) => {
    const position = convertCoordinatesWithPadding(x, y);

    return (
      position.y >= 0 &&
      position.y <= topFieldHeight &&
      ((position.x >= 0 && position.x <= sectionWidth) ||
        (position.x > sectionWidth && position.x <= 2 * sectionWidth) ||
        (position.x > 2 * sectionWidth && position.x <= fieldWidth))
    );
  };

  const isInBottomField = (x, y) => {
    const position = convertCoordinatesWithPadding(x, y);

    return (
      position.y >= topFieldHeight && // This ensures it starts after the top field
      position.y <= fieldHeight &&
      position.x >= 0 &&
      position.x <= fieldWidth
    );
  };

  const counts = {
    leftTop: 0,
    middleTop: 0,
    rightTop: 0,
    bottom: 0,
  };

  displayedEvents?.forEach((event) => {
    // mirrorCoordinates need to mirror coords if the coordinates came from the other side of the field
    const { coord_x, coord_y } = mirrorCoordinates(event);
    const position = convertCoordinatesWithPadding(coord_x, coord_y);
    if (isInTopField(coord_x, coord_y)) {
      if (position.x >= 85 && position.x <= 85 + 160) {
        counts.leftTop++;
      } else if (position.x > 85 + 160 && position.x <= 85 + 160 * 2) {
        counts.middleTop++;
      } else if (position.x > 85 + 160 * 2 && position.x <= 649 - 85) {
        counts.rightTop++; // Updated to use full field width
      }
    } else if (isInBottomField(coord_x, coord_y)) {
      counts.bottom++;
    }
  });

  const handleChangeTimelineRange = ({ min, max }) => {
    if (dataForTimeLineChange.current?.events?.length > 0) {
      const newDisplayedEvents = dataForTimeLineChange.current?.events?.filter(
        (item) => +item.second >= min * 60 && +item.second <= max * 60
      );
      setDisplayedEvents(newDisplayedEvents);
    }
  };

  const debouncedChangeTimeLineRange = debounce(handleChangeTimelineRange, 500);

  const resetAllSettings = () => {
    setDisplayedEvents([]);
    setChoosenCompetition([]);
    SetLastMatches(0);
    setSelectedMatch('');
    setSelectedSeason("");
    setCheckedIds([]);
    setMarkers([]);
    setResponse(null);
    setReponseOpponents(null);
    setOpponentsMarkers([]);
  }

  const seasonDropdownRef = useOutsideClick(() => {
    if (open) {
      setOpen(false);
    }
  });

  const competitionDropdownRef = useOutsideClick(() => {
    if (openCompetition) {
      setOpenCompetition(false);
    }
  });

  const mathesCountDropdownRef = useOutsideClick(() => {
    if (openMatchesCount) {
      setOpenMatchesCount(false);
    }
  });

  const dropdownRef = useOutsideClick(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  return (
    <>
      <EventsMatchesWrap>
        <ListOfMatches>
          <EventCardWrapper>
            <OwnOpponnentSwitcherWrapper>
              <div
                className={`tabItem ${typeShots === "own" ? "active" : ""}`}
                onClick={() => setTypeShots("own")}
              >
                Own Crosses
              </div>
              <div
                className={`tabItem ${typeShots === "opponent" ? "active" : ""
                  }`}
                onClick={() => setTypeShots("opponent")}
              >
                Opponent Crosses
              </div>
            </OwnOpponnentSwitcherWrapper>
            <h3>Choose Season & Match Parameters</h3>
            <EventCard>
              <EventContent>
                <DropdownWrapper ref={seasonDropdownRef} disabled={loadingSeason}>
                  <div
                    style={{ position: "relative" }}
                    onClick={() => setOpen(!open)}
                  >
                    <TextInput
                      type="text"
                      value={
                        selectedSeason.length == 0
                          ? "Select Season"
                          : selectedSeason
                      }
                      readOnly
                    />
                    <img
                      src={open ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {open && (
                    <OpenedDropdown>
                      {players.available_seasons?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleSeasonChoose(item)}
                        >
                          {item.years}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <Dropdown ref={competitionDropdownRef} disabled={loadingSeason}>
                  <DropdownButton
                    onClick={() => setOpenCompetition(!openCompetition)}
                  >
                    {choosenCompetition.length === 0
                      ? "Select Competition"
                      : `${choosenCompetition.length} selected`}
                    <img src={openCompetition ? arrowIconUp : arrowIconDown} />
                  </DropdownButton>
                  <DropdownContent show={openCompetition}>
                    {uniqueCompetitions?.map((item) => (
                      <DropdownItem
                        key={item?.competition__id}
                        selected={choosenCompetition.includes(item.competition__id)}
                        onClick={() => handleCompetitionChoose(item)}
                      >
                        {item?.title}
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </Dropdown>

                <DropdownWrapper ref={mathesCountDropdownRef} disabled={loadingSeason}>
                  <div
                    style={{ position: "relative" }}
                    onClick={() => setOpenMatchesCount(!openMatchesCount)}
                  >
                    <TextInput
                      type="text"
                      value={
                        selectedMatch.length == 0 ? "PRE-SET" : selectedMatch
                      }
                      readOnly
                    />

                    <img
                      src={openMatchesCount ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  {openMatchesCount && (
                    <OpenedDropdown>
                      {matches?.map((item, index) => (
                        <div key={index} onClick={() => handleMatches(item)}>
                          {item.title}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <Dropdown ref={dropdownRef} disabled={loadingSeason}>
                  <DropdownButton
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {matchesList.length === 0
                      ? "Select Matches"
                      : `${matchesList.length} selected`}
                    <img src={showDropdown ? arrowIconUp : arrowIconDown} />
                  </DropdownButton>
                  <DropdownContent show={showDropdown}>
                    {displayedMathes.map((item, index) => (
                      <DropdownItem
                        key={index}
                        selected={matchesList.includes(item.id)}
                        onClick={() => handleSelectionChange(item.id)}
                      >
                        {item.home_team} {item.score} {item.away_team}{" "}
                        {item.competition}
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </Dropdown>
              </EventContent>
            </EventCard>
          </EventCardWrapper>

          <EventCardWrapper>
            <h3>Choose Other Parameters</h3>
            <EventCard style={{ maxHeight: "400px", overflow: "scroll" }}>
              <EventContent>
                <TabsWrapper>
                  <TopList
                    needBackGroundColor={true}
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <TabListWrapper
                      style={{
                        overflow: "auto",
                        textWrap: "nowrap",
                        scrollbarWidth: "auto",
                        overflowY: "hidden",
                      }}
                    >
                      <TabTitle>Passes</TabTitle>
                      <TabTitle>Crosses</TabTitle>
                    </TabListWrapper>
                  </TopList>
                  <TabContent>
                    <ParametersTabWrapper>
                      {paramsShotList?.filter(
                          (item) =>
                            item.name.includes("Pass") &&
                            !item.name.includes("%")
                        )?.map((item, index) => (
                          <div className="checkboxField" key={index}>
                            <label htmlFor={item.id}>
                              <input
                                id={item.id}
                                type="checkbox"
                                checked={checkedIds.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                              {item.name}
                            </label>
                          </div>
                        ))}
                    </ParametersTabWrapper>
                  </TabContent>
                  <TabContent>
                    <ParametersTabWrapper>
                      {paramsShotList?.filter(
                          (item) =>
                            item.name.includes("Cross") &&
                            !item.name.includes("%")
                        )?.map((item, index) => (
                          <div className="checkboxField" key={index}>
                            <label htmlFor={item.id}>
                              <input
                                id={item.id}
                                type="checkbox"
                                checked={checkedIds.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                              {item.name}
                            </label>
                          </div>
                        ))}
                    </ParametersTabWrapper>
                  </TabContent>
                </TabsWrapper>
              </EventContent>
            </EventCard>
          </EventCardWrapper>
        </ListOfMatches>

        {loadingMarkers ? (
          <LoadingState>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingState>
        ) : (
          <FootballField>
            <FootballFielOptions>
              {/* <VideoSettingsWrapper></VideoSettingsWrapper> */}
              <Button
                onClick={() => resetAllSettings()}
                title={"Reset all"}
                size={"normal"}
              />
              {displayedEvents?.length > 0 ? (
                <Button
                  title={`Watch ${displayedEvents?.length} events`}
                  onClick={() =>
                    navigate(
                      `/tournaments/match/video?title=${typeShots === 'own' ? 'Events' : 'Opponent Events'} - ${players?.team?.name}&markers=${displayedEvents
                        ?.map((item) => item.id)
                        ?.join(",")}`
                    )
                  }
                  onContextMenu={() =>
                    window.open(
                      `/tournaments/match/video?title=${typeShots === 'own' ? 'Events' : 'Opponent Events'} - ${players?.team?.name}&markers=${displayedEvents
                        ?.map((item) => item.id)
                        ?.join(",")}`
                    )
                  }
                />
              ) : (
                <Button
                  title={`Watch ${displayedEvents?.length > 0 ? displayedEvents?.length : "0"
                    } events`}
                  disabled
                />
              )}
            </FootballFielOptions>

            <DirectionStatus>
              <label>
                <input
                  type="checkbox"
                  id="specifyColor"
                  checked={showDirection}
                  onChange={() => setShowDirection(!showDirection)}
                />
                Show Direction
              </label>
            </DirectionStatus>

            <svg
              width={fieldWidth}
              height={fieldHeight}
              viewBox={`0 0 ${fieldWidth} ${fieldHeight}`}
              preserveAspectRatio="xMidYMid meet"
              style={{ border: "1px solid black" }}
            >
              {/* Background Image */}

              <image
                href={backgroundImage}
                x={fieldPadding}
                y={fieldPadding}
                width={fieldWidth}
                height={fieldHeight}
                preserveAspectRatio="xMidYMid slice"
              />

              {/* Render the field layout here */}
              {displayedEvents?.map((event) => {
                let coord_x, coord_y, coord_x_destination, coord_y_destination;
                // mirrorCoordinates need to mirror coords if the coordinates came from the other side of the field
                ({
                  coord_x,
                  coord_y,
                  coord_x_destination,
                  coord_y_destination,
                } = mirrorCoordinates(event));

                const position = convertCoordinatesWithPadding(
                  coord_x,
                  coord_y
                );

                // Adjust coordinates to fit portrait aspect ratio
                const adjustedX = position.x;
                const adjustedY = position.y;
                const arrowsEnd = convertCoordinatesWithPadding(
                  coord_x_destination,
                  coord_y_destination
                );
                return (
                  <React.Fragment key={event.id}>
                    {showDirection && arrowsEnd?.x > 0 && arrowsEnd.y >= 0 && (
                      <>
                        <defs>
                          <marker
                            id={`arrow-${event.id}`}
                            markerWidth="5"
                            markerHeight="5"
                            refX="0"
                            refY="2"
                            orient="auto"
                          >
                            <path d="M0,0 L0,3 L4,1.5 z" fill="#000" />
                          </marker>
                        </defs>

                        <line
                          x1={adjustedX}
                          y1={adjustedY}
                          x2={arrowsEnd.x}
                          y2={arrowsEnd.y}
                          stroke="#BFE82D"
                          strokeWidth="2"
                          strokeDasharray="0"
                        />
                      </>
                    )}
                    {event.action === "Shot wide" ||
                      event.action === "Blocked shot" ? (
                      <a
                        href={`/tournaments/match/video?title=${event.action} - ${event?.creator?.display_name}&markers=${event.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <image
                          href={missedShot}
                          x={adjustedX}
                          y={adjustedY}
                          onMouseEnter={() => setHoveredEvent(event)}
                          onMouseLeave={() => setHoveredEvent(null)}
                        // onContextMenu={() =>
                        //   window.open(
                        //     `/tournaments/match/video?markers=${event.id}`
                        //   )
                        // }
                        // onClick={() =>
                        //   navigate(
                        //     `/tournaments/match/video?markers=${event.id}`
                        //   )
                        // }
                        />
                      </a>
                    ) : event.action === "Goal" ? (
                      <a
                        href={`/tournaments/match/video?title=${event.action} - ${event?.creator?.display_name}&markers=${event.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <image
                          href={goal}
                          x={adjustedX}
                          y={adjustedY}
                          onMouseEnter={() => setHoveredEvent(event)}
                          onMouseLeave={() => setHoveredEvent(null)}
                        // onContextMenu={() =>
                        //   window.open(
                        //     `/tournaments/match/video?markers=${event.id}`
                        //   )
                        // }
                        // onClick={() =>
                        //   navigate(
                        //     `/tournaments/match/video?markers=${event.id}`
                        //   )
                        // }
                        />
                      </a>
                    ) : (
                      <a
                        href={`/tournaments/match/video?title=${event.action} - ${event?.creator?.display_name}&markers=${event.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <circle
                          key={event.id}
                          cx={adjustedX}
                          cy={adjustedY}
                          r="5"
                          fill="#040802"
                          onMouseEnter={() => setHoveredEvent(event)}
                          onMouseLeave={() => setHoveredEvent(null)}
                        // onContextMenu={() =>
                        //   window.open(
                        //     `/tournaments/match/video?markers=${event.id}`
                        //   )
                        // }
                        // onClick={() =>
                        //   navigate(
                        //     `/tournaments/match/video?markers=${event.id}`
                        //   )
                        // }
                        />
                      </a>
                    )}
                  </React.Fragment>
                );
              })}
            </svg>

            {/* Info Box */}
            <InfoBox
              visible={!!hoveredEvent}
              top={
                hoveredEvent
                  ? convertCoordinates(
                    hoveredEvent.coord_x,
                    hoveredEvent.coord_y
                  ).y
                  : 0
              }
              left={
                hoveredEvent
                  ? convertCoordinates(
                    hoveredEvent.coord_x,
                    hoveredEvent.coord_y
                  ).x
                  : 0
              }
            >
              {hoveredEvent ? (
                <>
                  <img
                    src={hoveredEvent.creator.photo}
                    alt={hoveredEvent.creator.photo}
                  />
                  <h4>{hoveredEvent.creator.display_name}</h4>
                  <p>
                    {hoveredEvent.match.home_team.name}{" "}
                    {hoveredEvent.match.away_team.name},{" "}
                    {hoveredEvent.match.competition.name}
                  </p>
                  <h5>{hoveredEvent.action}</h5>
                  {hoveredEvent.recipient && (
                    <p>To {hoveredEvent.recipient.display_name}</p>
                  )}
                </>
              ) : (
                <p>No player info available</p>
              )}
            </InfoBox>

            <TimelineChange
              onChange={debouncedChangeTimeLineRange}
              min={timeLineRange?.min}
              max={timeLineRange?.max}
              styleContainer={{ marginTop: 35, marginBottom: 20 }}
            />

            <FootballFieldCount>
              <img src={backgroundImage} alt={backgroundImage} />

              <FieldCount>
                <TopFields>
                  <LeftTopField>
                    {displayedEvents?.length > 0
                      ? `${Math.round(
                        (counts.leftTop / displayedEvents?.length) * 100
                      )} %`
                      : "0"}
                  </LeftTopField>
                  <MiddleTopField>
                    {displayedEvents?.length > 0
                      ? `${Math.round(
                        (counts.middleTop / displayedEvents?.length) * 100
                      )} %`
                      : "0"}
                  </MiddleTopField>
                  <RightTopField>
                    {displayedEvents?.length > 0
                      ? `${Math.round(
                        (counts.rightTop / displayedEvents?.length) * 100
                      )} %`
                      : "0"}
                  </RightTopField>
                </TopFields>
                <BottomFields>
                  {displayedEvents?.length > 0
                    ? `${Math.round(
                      (counts.bottom / displayedEvents?.length) * 100
                    )} %`
                    : "0"}
                </BottomFields>
              </FieldCount>
            </FootballFieldCount>
          </FootballField>
        )}
      </EventsMatchesWrap>
    </>
  );
};

export default CrossesTeam;

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import VideoDownloader from '../../pages/tournametns/player/ui/VideoDownloader';
import useOutsideClick from '../../hooks/useOutsideClick';

const DownloadButton = styled.div`
position: fixed;
top: 10px;
left: 30px;
z-index: 3;

.videoListProgress {
    width: 40vw;
    border: 1px solid rgba(24, 31, 4, 1);
    background-color: ${(props) => props.theme.black};
    display: flex;
    flex-direction: column;
    gap: 10px;
	padding: 10px 25px;
    border-radius: 4px;
    transition: all ease-in-out .5s;
    margin-top: 20px;

    & > .videoListProgressContent {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > div > .progressCancelBtn {
            cursor: pointer;
            background-color: ${props => props.theme.green};
            color: ${props => props.theme.black};

            font-family: "SatoshiMedium";
            text-decoration: none;
            font-size: 12px;
            text-indent: 15px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            height: 25px;
            width: 150px;
            justify-content: center;
            margin-top: 10px;
        }

        & > div > p {
            color: ${(props) => props.theme.white};
            font-family: 'SatoshiMedium';
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        & > div > .progressBarContainer {
            background-color:  ${(props) => props.theme.lines};
            width: 100%; 
            border-radius: 5px;

            & > .progressBarDone {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                background-color: ${(props) => props.theme.green};
                color: ${(props) => props.theme.black};
                font-family: 'SatoshiMedium';
                font-size: 12px;
                text-align: center;
            }
        }
    }
}


.buttonDownload {
	display: inline-block;
    position: relative;
	padding: 10px 25px;
  
	background-color: ${props => props.theme.green};
	color: ${props => props.theme.black};
  
	font-family: "SatoshiMedium";
	text-decoration: none;
	font-size: 0.9em;
	text-align: center;
	text-indent: 15px;
    border-radius: 4px;
}

.buttonDownload:hover {
	background-color: #333;
	color: white;
    cursor: pointer;
}

.buttonDownload:before, .buttonDownload:after {
	content: ' ';
	display: block;
	position: absolute;
	left: 15px;
	top: 52%;
}

/* Download box shape  */
.buttonDownload:before {
	width: 10px;
	height: 2px;
	border-style: solid;
	border-width: 0 2px 2px;
}

/* Download arrow shape */
.buttonDownload:after {
	width: 0;
	height: 0;
	margin-left: 3px;
	margin-top: -7px;
  
	border-style: solid;
	border-width: 4px 4px 0 4px;
	border-color: transparent;
	border-top-color: inherit;
	
	animation: downloadArrow 2s linear infinite;
	animation-play-state: running;
}

.buttonDownload:hover:before {
	border-color: ${props => props.theme.green};
}

.buttonDownload:hover:after {
	border-top-color: ${props => props.theme.green};
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
	/* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
	0% {
		margin-top: -7px;
		opacity: 1;
	}
	
	0.001% {
		margin-top: -15px;
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		margin-top: 0;
		opacity: 0;
	}
}
`

export const DowloadVideoList = () => {
    const videoLinks = useSelector(state => state.download_videos.videoLinks);

    const [expanded, setExpanded] = useState(false);

    const ref = useOutsideClick(() => {
        if (expanded) {
            setExpanded(false);
        }
    });

    const handleClickButton = () => {
        setExpanded(prev => !prev);
    }

    if (videoLinks?.length === 0) return null;

    return (
        <>
            <DownloadButton ref={ref}>
                <div
                    title='Click to view the download progress'
                    className='buttonDownload'
                    onClick={handleClickButton}
                >
                    Download {videoLinks?.length}
                </div>

                <div
                    onClick={(e) => e.stopPropagation()}
                    className='videoListProgress'
                    style={{
                        opacity: !expanded ? 0 : 1,
                        pointerEvents: !expanded ? 'none' : 'auto'
                    }}>
                    <div className='videoListProgressContent'>
                        {
                            videoLinks?.map(link => (
                                <VideoDownloader key={link} videoUrl={link} />
                            ))
                        }
                    </div>
                </div>
            </DownloadButton >
        </>
    )
}

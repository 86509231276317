import { configureStore } from '@reduxjs/toolkit';
import { tournamentReducer } from '../../../store/tournament/tournamentSlice';
import { useDispatch } from 'react-redux';
import { matchReducer } from '../../../store/match/matchSlice';
import { playerReducer } from '../../../store/player/playerSlice';
import { searchReducer } from '../../../store/search/searchSlice';
import { teamReducer } from '../../../store/team/teamSlice';
import { userReducer } from '../../../store/user/userSlice';
import { competitionReducer } from '../../../store/competition/competitionSlice';
import { seasonReducer } from '../../../store/season/seasonSlice';
import { videoCutsReducer } from '../../../store/video_cuts/videoCutsSlice';
import { pitchReducer } from '../../../store/pitch/pitchSlice';
import { downloadVideosReducer } from '../../../store/download_videos/downloadVideosSlice';
import { translatesReducer } from '../../../store/languages/languagesSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  reducer: {
    competiton: competitionReducer,
    match: matchReducer,
    pitch: pitchReducer,
    player: playerReducer,
    search: searchReducer,
    season: seasonReducer,
    team: teamReducer,
    tournament: tournamentReducer,
    user: userReducer,
    video_cuts: videoCutsReducer,
    download_videos: downloadVideosReducer,
    lexicon: translatesReducer,
  },
});

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();

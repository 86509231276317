import styled from "styled-components";
import { breakpoints } from "./size";

export const TopCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.linesOpacity};
  margin-bottom: 60px;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 40px;
  }
`;

export const TopContent = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: start;
  }
`;

export const LeftInfo = styled.div`
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
`;

export const RightInfo = styled.div`
  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 38px;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

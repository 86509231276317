import React, { useState, useEffect } from "react";

// Style
import {
  TableWrap,
  TableContent,
  TableNames,
  AlfabetSort,
  DateSort,
  LeftContent,
  MidContent,
  RightContent,
  NoVideo,
  AddButton,
  SeeMore,
  Actions,
  ActionWrap,
  EmailList,
  EmailListWrap,
  Divider,
} from "../../style/Playlist";

// Componenst
import Modal from "../../components/modal/index";
import Empty from "../../components/empty/index";

// Images
import arrow from "../../images/down.svg";
import share from "../../images/ic_share.svg";
import share_blue from "../../images/share_blue.svg";
import sort from "../../images/ic_sort.svg";
import closeIcon from "../../images/ic_close.svg";

// Language
import { useTranslation } from "react-i18next";
import TextInput from "../inputs/TextInput";
import Button from "../button";
import { $apiV1 } from "../../config/api";

const LinkTable = ({ table }) => {
  const { t, i18n } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);
  const [openShareVideo, setOpenShareVideo] = useState(false);
  const [openSuccessShareVideo, setOpenSuccessShareVideo] = useState(false);

  const [videoName, setVideoName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailList, setEmailList] = useState([]);

  // Sorting State
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("title");

  const sortData = (data) => {
    // Ensure data is an array
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return [];
    }

    return data.sort((a, b) => {
      if (sortBy === "title") {
        return sortOrder === "asc"
          ? a.playlist_title.localeCompare(b.playlist_title)
          : b.playlist_title.localeCompare(a.playlist_title);
      } else if (sortBy === "date") {
        return sortOrder === "asc"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }
      return 0;
    });
  };

  const sortedTable = sortData(table.results);

  // Functions for handling sort
  const handleSortByTitle = () => {
    setSortBy("title");
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSortByDate = () => {
    setSortBy("date");
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleShareVideo = (item) => {
    setVideoName(item);
    setOpenShareVideo(!openShareVideo);
    setEmailList([]);
    setCurrentEmail("");
  };

  const handleEmailList = (newValue) => {
    setCurrentEmail(newValue);
  };

  const handleAddEmail = () => {
    setEmailList([...emailList, currentEmail]);
    setCurrentEmail("");
  };

  const handleRemoveEmail = (index) => {
    setEmailList(emailList.filter((_, i) => i !== index));
  };

  useEffect(() => {}, [emailList]);

  const handleSharePlayers = async (videoName) => {
    try {
      await $apiV1.post(
        `/users/share/`,
        { email: emailList, playlist: videoName.playlist },
        setOpenSuccessShareVideo(!openSuccessShareVideo),
        setOpenShareVideo(false)
      );
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const modal = (
    <>
      {/* Share folder */}
      <Modal isOpen={openShareVideo} setIsOpen={setOpenShareVideo} closeIc>
        <h1>{t("playlist.shareFolderTitle")}</h1>
        <p>
          Share {videoName?.title} via next social media, or just copy link.
        </p>
        <TextInput
          type="email"
          value={currentEmail}
          onChange={handleEmailList}
          placeholder="Enter Email"
        />

        <Button
          title="Add Email"
          size={"full"}
          color={"secondary"}
          onClick={() => handleAddEmail(currentEmail)}
          style={{ marginTop: "30px" }}
        />

        {emailList.length > 0 && (
          <EmailList>
            {emailList.map((email, index) => (
              <EmailListWrap key={index}>
                {email}{" "}
                <img
                  src={closeIcon}
                  alt={closeIcon}
                  onClick={() => handleRemoveEmail(index)}
                />
              </EmailListWrap>
            ))}
            <Button
              title="Share playlist"
              size={"full"}
              color={"secondary"}
              onClick={() => handleSharePlayers(videoName)}
              style={{ marginTop: "30px" }}
            />
          </EmailList>
        )}
      </Modal>
      {/* End Share folder */}

      {/* Success Copy link */}
      <Modal
        isOpen={openSuccessShareVideo}
        setIsOpen={setOpenSuccessShareVideo}
        successIc
      >
        <h1>{t("playlist.successCopyTitle")}</h1>
        <p style={{ marginBottom: "40px" }}>{t("playlist.successCopyDesc")}</p>

        <Button
          title={t("playlist.successCopyButton")}
          size={"full"}
          onClick={() => setOpenSuccessShareVideo(!openSuccessShareVideo)}
        />
      </Modal>
      {/* Success Copy link */}
    </>
  );

  const toggleOpen = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <TableNames>
        <AlfabetSort onClick={handleSortByTitle} style={{ cursor: "pointer" }}>
          <h6>{t("playlist.sortAlphabet")}</h6>
          <img src={sort} alt="sort" />
        </AlfabetSort>
        <Divider style={{ marginRight: "35px" }}></Divider>
        <DateSort onClick={handleSortByDate} style={{ cursor: "pointer" }}>
          <h6>{t("playlist.sortDate")}</h6>
          <img src={sort} alt="sort" />
        </DateSort>
      </TableNames>
      {sortedTable.length > 0 ? (
        <TableWrap style={{ height: "fit-content" }}>
          {sortedTable.map((item, index) => {
            return (
              <>
                <TableContent key={index}>
                  <LeftContent
                    style={{ color: "#fff" }}
                    onClick={() => toggleOpen(index)}
                  >
                    <img src={arrow} alt={arrow} />{" "}
                    <h3>{item.playlist_title}</h3>
                  </LeftContent>
                  <MidContent>
                    <h5>{item.views_count}</h5>
                  </MidContent>
                  <MidContent>
                    <h5>{item.date?.split("T")?.[0]}</h5>
                  </MidContent>
                  <RightContent>
                    <>
                      <img
                        src={localStorage.getItem("theme") === "light" ? share_blue : share}
                        alt={share}
                        onClick={() => handleShareVideo(item)}
                        // onClick={() => setOpenShareVideo(!openShareVideo)}
                      />
                    </>
                  </RightContent>
                </TableContent>

                {openIndex === index && (
                  <>
                    {item.share_with.length > 0 ? (
                      item.share_with.map((item, index) => {
                        return (
                          <TableContent>
                            <LeftContent style={{ color: "#fff" }}>
                              <h3>{item}</h3>
                            </LeftContent>
                          </TableContent>
                        );
                      })
                    ) : (
                      <NoVideo>
                        <h6>Nothing in here for now. Start adding videos.</h6>
                      </NoVideo>
                    )}
                  </>
                )}
              </>
            );
          })}
        </TableWrap>
      ) : (
        <Empty
          title={t("playlist.emptyPlaylistTitle")}
          text={t("playlist.emptyPlaylistDesc")}
          button1={t("playlist.emptyPlaylistButton")}
        />
      )}
      {modal}
    </>
  );
};

export default LinkTable;
